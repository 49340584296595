import React, { Component } from "react";
import { withTranslation } from "react-i18next";
import { connect } from "react-redux";
import withRouter from "helpers/withRouter";
import BannerCustom from '../../components/Banners/BannerCustom'
import {
  deleteStore,
  editStoreStatus,
  fetchListStoreStatus,
} from "../../redux/features/manager/managerSlice";
import {
  fetchNewMessagesCount,
  resetSystemMessage,
} from "../../redux/features/messenger/messengerSlice";
import {
  fetchCountries,
  fetchCurrency,
  fetchServices,
} from "../../redux/features/other/otherSlice";
import { fetchCurrentPlan } from "../../redux/features/subscription/subscriptionSlice";
import {
  fetchGbrStates,
  fetchStates,
} from "../../redux/features/tax-settings/taxSettingsSlice";
import { updateTransactionsIntegration } from "../../redux/features/transactions/transactionsSlice";
import { fetchUserGuide } from "../../redux/features/user/userSlice";
import {
  activateWebsite,
  cancelIntegration,
  editStore,
  fetchAmazonSPCountries,
  fetchWebsites,
  fetchWebsitesErrorList,
  integrateAmazon,
  integrateEbay,
  integrateEtsy,
  integrateMagento,
  integrateMagento2,
  integrateShopify,
  integrateStripe,
  integrateWooCom,
  resetWebsiteToken,
} from "../../redux/features/websites/websitesSlice";
import {
  Button,
  Card,
  Page,
  Badge,
  ButtonGroup,
  Banner,
  FormLayout,
  Select,
  Tooltip,
  Checkbox,
  TextField,
  Spinner,
  ChoiceList,
  Text,
  DataTable,
  Box,
  Link, BlockStack, InlineStack, Divider,
} from "@shopify/polaris";
import _ from "lodash";
import { parseServerError } from "utils/errors";
import Modal from "components/Modal/Modal";
import formatRow from "utils/formatRow";
import paginate from "utils/paginate";
import NoDataMessage from "components/NoDataMessage/NoDataMessage";
import Pagination from "components/Pagination/Pagination";
import InfoTooltip from "components/InfoTooltip/InfoTooltip";

import deleteIMG from "img/delete.svg";
import settingsIMG from "img/settings.svg";
import uploadCloudIMG from "img/uploadCloud.svg";
import eyeOnIMG from "img/eye-on.svg";
import eyeOffIMG from "img/eye-off.svg";
import plusIMG from "img/plusGrey.svg";
import activateIMG from "img/activate.svg";
import Platforms from "components/Platforms/Platforms";
import PageHelmet from "components/PageHelmet";
import GuideCompletionPopup from "components/GuideCompletionPopup/GuideCompletionPopup";
import { createCustomDispatch } from "helpers/customDispatch";
import { AddIcon } from "icons";

const defaultLimit = 10;

class Stores extends Component {
  constructor(props) {
    super(props);

    this.state = {
      sortingColumns: [],
      searchColumn: "all",
      pagination: {
        page: 1,
        perPage: 10,
      },
      isFormUploaded: false,
      isChecked: false,
      showToken: false,
      isEditSettings: false,
      isAddService: false,
      isAddPlatform: false,
      selectedServices: [],
      amazonSP: [],

      actionDialogs: {
        updateSettings: { open: false },
        accessToken: { open: false },
        resetToken: { open: false },
        activate: { open: false },
        errorList: { open: true },
        status: { open: false },
        delete: { open: false },
      },
    };

    this.handleResetTokenOpen = this.handleResetTokenOpen.bind(this);
    this.doResetToken = this.doResetToken.bind(this);
    this.doActivate = this.doActivate.bind(this);
  }

  componentDidMount() {
    this.props.fetchUserGuide();
    this.props.fetchWebsites();
    this.props.fetchCountries();
    this.props.fetchCurrency();
    this.props.fetchServices();
    this.props.fetchListStoreStatus();
    this.props.fetchWebsitesErrorList();
    this.props.fetchCurrentPlan();
    this.props.fetchStates();
    this.props.fetchGbrStates();
    this.props.fetchAmazonSPCountries();
  }

  onSelectPage = (page) => {
    const rows = this.props.websites;

    const pages = Math.ceil(rows.length / this.state.pagination.perPage);

    this.setState({
      pagination: {
        ...this.state.pagination,
        page: Math.min(Math.max(page, 1), pages),
      },
    });
  };

  getStatesOption = () => {
    const { usStates } = this.props;
    return [
      {
        value: "",
        label: "",
      },
      ...usStates.map((state) => ({
        value: state.code,
        label: state.name,
      })),
    ];
  };

  getGbrStatesOption = () => {
    const { gbrStates } = this.props;
    return [
      {
        value: "",
        label: "",
      },
      ...gbrStates.map((state) => ({
        value: state.code,
        label: state.name,
      })),
    ];
  };

  getDataForIntegration = (shopName) => {
    const { selectedServices } = this.state;
    return {
      website: shopName,
      good_or_service: selectedServices,
    };
  };

  getDisabledStatus = () => {
    const {
      platform,
      storeName,
      amazonSP,
      shopUrlMagento,
      accSecretMagento,
      accTokenMagento,
      clSecretMagento,
      clKeyMagento,
      keyMagento,
      shopUrlWooCom,
      keyWooCom,
    } = this.state;
    let disabled = false;

    switch (platform) {
      case "ebay":
        disabled = !storeName;
        break;
      case "shopify":
        disabled = !storeName;
        break;
      case "amazon":
        disabled = !storeName || _.isEmpty(amazonSP);
        break;
      case "etsy":
        disabled = !storeName;
        break;
      case "woocommerce":
        disabled = !storeName || !shopUrlWooCom || !keyWooCom;
        break;
      case "magento1":
        disabled = !storeName || !shopUrlMagento || !keyMagento;
        break;
      case "magento":
        disabled =
          !storeName ||
          !shopUrlMagento ||
          !accSecretMagento ||
          !accTokenMagento ||
          !clSecretMagento ||
          !clKeyMagento;
        break;
      case "stripe":
        disabled = !storeName;
        break;
      default:
        disabled = true;
    }

    return disabled;
  };

  resetChangeStatusMessages = () => {
    this.setState({
      status: false,
      changeStoreStatusSuccess: false,
      errorChangeStoreStatus: false,
    });
  };

  handleActionDialogsClose = (name) => {
    const { actionDialogs } = this.state;
    actionDialogs[name].open = false;
    actionDialogs.cellData = {};
    this.setState({ actionDialogs });
  };

  handleActionDialogsOpen = (name, data = {}) => {
    const { actionDialogs } = this.state;
    actionDialogs[name].open = true;
    actionDialogs.cellData = data;

    if (name === "updateSettings") {
      this.setState({
        storeName: data.website,
        storeUrl: data.website_url,
        platform: data.platform,
        actionDialogs,
      });
    } else {
      this.setState({ actionDialogs });
    }
  };

  handleResetTokenOpen = () => {
    const { actionDialogs } = this.state;
    const data = actionDialogs.cellData;
    this.handleActionDialogsClose("accessToken");
    this.handleActionDialogsOpen("resetToken", data);
  };

  doDeleteStore = () => {
    const { actionDialogs } = this.state;
    const id = _.get(actionDialogs, "cellData.id");

    if (id) {
      this.props
        .deleteStore(id)
        .then(() => {
          this.props.fetchWebsites();
          this.handleActionDialogsClose("delete");
        })
        .catch(_.noop);
    }
  };

  doResetToken() {
    const { actionDialogs } = this.state;
    if (actionDialogs.cellData && actionDialogs.cellData.id) {
      this.props
        .resetWebsiteToken({ id: actionDialogs.cellData.id })
        .then(() => {
          this.props.fetchWebsites();
          this.handleActionDialogsClose("resetToken");
        })
        .catch(_.noop);
    }
  }

  doActivate() {
    const { actionDialogs } = this.state;
    if (actionDialogs.cellData && actionDialogs.cellData.id) {
      this.props
        .activateWebsite({ id: actionDialogs.cellData.id })
        .then(() => {
          this.props.fetchWebsites();
          this.handleActionDialogsClose("activate");
        })
        .catch(_.noop);
    }
  }

  doEditStoreStatus = (cellData) => {
    const { status } = this.state;

    const data = {
      id: cellData.id,
      status,
    };
    this.props
      .editStoreStatus(data)
      .then(() => {
        this.props.fetchWebsites();
        this.setState({ changeStoreStatusSuccess: true });
      })
      .catch((err) => {
        this.setState({ errorChangeStoreStatus: err.error });
      });
  };

  doCancelIntegration = () => {
    const { id } = this.state.actionDialogs.cellData;
    const actionDialogs = { ...this.state.actionDialogs };
    actionDialogs.cellData.platform = null;

    this.props
      .cancelIntegration(id)
      .then(() => {
        this.props.fetchWebsites();
        this.setState({
          actionDialogs,
          isAddPlatform: true,
        });
      })
      .catch((err) => {
        this.setState({
          errorCancelIntegration: err.error,
        });
      });
  };

  doIntegrate = () => {
    const { platform, storeName } = this.state;
    switch (platform) {
      case "ebay":
        this.handleIntegrateEbay(storeName);
        break;
      case "shopify":
        this.handleIntegrateShopify(storeName);
        break;
      case "amazon":
        this.handleIntegrateAmazon(storeName);
        break;
      case "etsy":
        this.handleIntegrateEtsy(storeName);
        break;
      case "woocommerce":
        this.handleIntegrateWooCom(storeName);
        break;
      case "magento1":
        this.handleIntegrateMagento(storeName);
        break;
      case "magento":
        this.handleIntegrateMagento2(storeName);
        break;
      case "stripe":
        this.handleIntegrateStripe(storeName);
        break;
      default:
        return null;
    }
  };

  doEditStore = () => {
    const {
      storeName,
      storeUrl,
      country,
      countryDep,
      stateDep,
      zipDep,
      currency,
      actionDialogs,
      selectedServices,
    } = this.state;

    const data = {
      id: actionDialogs.cellData.id,
    };

    if (storeName) {
      data.website = storeName;
    }
    if (storeUrl) {
      data.website_url = storeUrl;
    }
    if (country) {
      data.country_id = country;
    }
    if (countryDep) {
      data.departure_country_id = countryDep;
    }
    if (stateDep) {
      data.departure_state = stateDep;
    }
    if (zipDep) {
      data.departure_zip = zipDep;
    }
    if (currency) {
      data.currency_code = currency;
    }

    data.good_or_service = [
      ...selectedServices,
      ...actionDialogs.cellData.services,
    ];

    this.props
      .editStore(data)
      .then(() => {
        this.props.fetchWebsites();
        this.setState({ editingStoreSuccess: true });
      })
      .catch(() => {
        this.setState({ editingStoreError: "Error" });
      });
  };

  doDeleteService = (serviceId) => {
    const services = [...this.state.actionDialogs.cellData.services];
    const selectedServices = [...this.state.selectedServices];
    const cellData = {
      ...this.state.actionDialogs.cellData,
    };
    services.map((serv, index) => {
      if (serv.code === serviceId) {
        services.splice(index, 1);
        cellData.services = services;
      }
    });
    selectedServices.map((serv, index) => {
      if (serv.code === serviceId) {
        selectedServices.splice(index, 1);
      }
    });

    this.setState({
      selectedServices,
      actionDialogs: {
        ...this.state.actionDialogs,
        cellData,
      },
    });
  };

  doAddService = () => {
    const { type, code, description } = this.state;
    const { services } = this.props;

    const selectedServices = [...this.state.selectedServices];
    if (type === "good") {
      if (!code) {
        this.setState({ codeError: "Code is required" });
        return;
      }
      if (!description) {
        this.setState({ descError: "Description is required" });
        return;
      }
      selectedServices.push({
        code,
        type,
        name: description,
      });
    } else {
      const service =
        services.find((serv) => serv.code === +code) || services[0];

      selectedServices.push({
        code: service.code,
        type,
        name: service.name,
      });
    }
    this.setState({
      selectedServices,
      code: "",
      description: "",
      type: "",
    });
  };

  handleIntegrateShopify = (shopName) => {
    const data = this.getDataForIntegration(shopName);
    this.props
      .integrateShopify(data)
      .then((resp) => {
        window.location = resp;
      })
      .catch((resp) => {
        this.setState({ intError: parseServerError(resp) });
      });
  };

  handleIntegrateEtsy = (shopName) => {
    const data = this.getDataForIntegration(shopName);
    this.props
      .integrateEtsy(data)
      .then((resp) => {
        window.location = resp;
      })
      .catch((resp) => {
        this.setState({ intError: parseServerError(resp) });
      });
  };

  handleIntegrateEbay = (shopName) => {
    const data = this.getDataForIntegration(shopName);
    this.props
      .integrateEbay(data)
      .then((resp) => {
        window.location = resp;
      })
      .catch((resp) => {
        this.setState({ intError: parseServerError(resp) });
      });
  };

  handleIntegrateAmazon = (shopName) => {
    const { amazonSP } = this.state;
    const data = this.getDataForIntegration(shopName);
    data.sp_country = amazonSP;

    this.props
      .integrateAmazon(data)
      .then((resp) => (window.location = resp))
      .catch((resp) => {
        this.setState({ intError: parseServerError(resp) });
      });
  };

  handleIntegrateMagento = (shopName) => {
    const { shopUrlMagento, keyMagento } = this.state;
    const data = this.getDataForIntegration(shopName);
    data.url = shopUrlMagento;
    data.api_key = keyMagento;

    this.props
      .integrateMagento(data)
      .then(() => {
        const cellData = {
          ...this.state.actionDialogs.cellData,
        };
        cellData.platform = "magento1";
        this.setState({
          intSuccess: true,
          shopUrlMagento: "",
          keyMagento: "",
          actionDialogs: {
            ...this.state.actionDialogs,
            cellData,
          },
        });
        this.props.fetchWebsites();
      })
      .catch((resp) => {
        this.setState({ intError: parseServerError(resp) });
      });
  };

  handleIntegrateMagento2 = (shopName) => {
    const {
      shopUrlMagento,
      accSecretMagento,
      accTokenMagento,
      clSecretMagento,
      clKeyMagento,
    } = this.state;
    const data = this.getDataForIntegration(shopName);
    data.url = shopUrlMagento;
    data.access_secret = accSecretMagento;
    data.access_token = accTokenMagento;
    data.client_secret = clSecretMagento;
    data.client_key = clKeyMagento;

    this.props
      .integrateMagento2(data)
      .then(() => {
        const cellData = {
          ...this.state.actionDialogs.cellData,
        };
        cellData.platform = "magento";
        this.setState({
          intSuccess: true,
          shopUrlMagento: "",
          accSecretMagento: "",
          accTokenMagento: "",
          clSecretMagento: "",
          clKeyMagento: "",
          actionDialogs: {
            ...this.state.actionDialogs,
            cellData,
          },
        });
        this.props.fetchWebsites();
      })
      .catch((resp) => {
        this.setState({ intError: parseServerError(resp) });
      });
  };

  handleIntegrateWooCom = (shopName) => {
    const { shopUrlWooCom, keyWooCom } = this.state;
    const data = this.getDataForIntegration(shopName);
    data.url = shopUrlWooCom;
    data.api_key = keyWooCom;

    this.props
      .integrateWooCom(data)
      .then(() => {
        const cellData = {
          ...this.state.actionDialogs.cellData,
        };
        cellData.platform = "woocommerce";
        this.setState({
          intSuccess: true,
          shopUrlWooCom: "",
          keyWooCom: "",
          actionDialogs: {
            ...this.state.actionDialogs,
            cellData,
          },
        });
        this.props.fetchWebsites();
      })
      .catch((resp) => {
        this.setState({ intError: parseServerError(resp) });
      });
  };

  handleIntegrateStripe = (shopName) => {
    const data = this.getDataForIntegration(shopName);
    this.props
      .integrateStripe(data)
      .then((resp) => {
        window.location = resp;
      })
      .catch((resp) => {
        this.setState({ intError: parseServerError(resp) });
      });
  };

  resetEditDialogData = () => {
    this.setState({
      showToken: false,
      isEditSettings: false,
      isAddService: false,
      isAddPlatform: false,
      isDeleteInt: false,
      platform: false,
      errorCancelIntegration: false,
      storeName: false,
      storeUrl: false,
      country: "",
      countryDep: "",
      zipDep: false,
      stateDep: false,
      selectedServices: [],
      editingStoreSuccess: false,
      editingStoreError: false,
      amazonSP: [],
      intError: false,
      intSuccess: false,
    });
  };

  handleUpdateTransactions = (website) => {
    // recieve transactions from integrated web store
    this.setState({ updating: true });
    this.props
      .updateTransactionsIntegration({
        website_id: website.id,
        platform: website.platform,
      })
      .then(() => {
        this.setState({ updating: false });
        setTimeout(() => {
          this.setState({ updating: false });
        }, 10000);
      })
      .catch(() => {
        this.setState({ updating: false });
      });
  };

  renderChangeStatusDialog = () => {
    const { listStoreStatus, user, t } = this.props;
    const {
      actionDialogs,
      status,
      changeStoreStatusSuccess,
      errorChangeStoreStatus,
    } = this.state;
    const statusArr = listStoreStatus
      ? listStoreStatus.map((st) => {
          return {
            value: st.value,
            label: st.value.toString().split("_")[0],
          };
        })
      : [];

    return (
      <Modal
        title={`${user.is_omp ? "Project" : t("stores.store")}: 
            ${actionDialogs.cellData && actionDialogs.cellData.website}`}
        infoIconVisible={false}
        visible={actionDialogs.status.open}
        onClose={() => {
          this.handleActionDialogsClose("status");
          this.resetChangeStatusMessages();
        }}
        content={
          <FormLayout>
            {changeStoreStatusSuccess && (
              <div>
                {/*<Banner tone='success'>*/}
                {/*  Status has been successfully changed*/}
                {/*</Banner>*/}
                 <BannerCustom
                   status={'success'}
                   message={'Status has been successfully changed'}
                 />
              </div>
            )}
            {errorChangeStoreStatus && (
              <div>
                {/*<Banner tone='critical'>{errorChangeStoreStatus}</Banner>*/}
                 <BannerCustom
                   status={'critical'}
                   message={errorChangeStoreStatus}
                 />
              </div>
            )}
            <br />
            <FormLayout.Group>
              <Select
                id='status'
                label={t("stores.selectStatus")}
                options={statusArr}
                onChange={(value) => this.setState({ status: value })}
                value={
                  status
                    ? status
                    : (actionDialogs.cellData &&
                        actionDialogs.cellData.status) ||
                      ""
                }
              />
            </FormLayout.Group>
          </FormLayout>
        }
        footer={
          <Button
            variant='primary'
            disabled={!status}
            onClick={() => this.doEditStoreStatus(actionDialogs.cellData)}
          >
            {t("stores.change")}
          </Button>
        }
      />
    );
  };

  renderDeleteStoreDialog = () => {
    const { user, t } = this.props;
    const { actionDialogs, isChecked } = this.state;

    const onClose = () => {
      this.handleActionDialogsClose("delete");
      this.setState({ isChecked: false });
    };

    return (
      <Modal
        title={`${t("stores.del")} 
            ${actionDialogs.cellData && actionDialogs.cellData.website}`}
        iconType='danger'
        description={`${
          user.is_omp ? t("stores.sureDel2") : t("stores.sureDel1")
        } 
        ${actionDialogs.cellData && actionDialogs.cellData.website}
        ${t("stores.sureDel2")}`}
        contentOnCenter
        onClose={onClose}
        visible={actionDialogs.delete.open}
        content={
          <>
            <Checkbox
              label={user.is_omp ? t("stores.aware2") : t("stores.aware")}
              checked={isChecked}
              onChange={(value) => {
                this.setState({ isChecked: value });
              }}
            />
          </>
        }
        footer={
          <ButtonGroup fullWidth>
            <Button onClick={onClose} size='large'>
              {t("stores.cancel")}
            </Button>
            <Button
              tone={'critical'}
              variant={'plain'}
              disabled={!isChecked}
              onClick={this.doDeleteStore}
              size='large'
            >
              {t("stores.del")}
            </Button>
          </ButtonGroup>
        }
      />
    );
  };

  renderStateAndZipInfo = (name, zip) => (
    <div style={{ display: 'flex', justifyContent: 'space-evenly' }}>
      <div>
        <p>{this.props.t("stores.departureState")}</p>
        <p>{name}</p>
      </div>
      <div>
        <p>{this.props.t("stores.departureZip")}</p>
        <p>{zip}</p>
      </div>
    </div>
  );

  renderEditStateAndZip = () => {
    const { actionDialogs } = this.state;
    const { t } = this.props;
    return (
      <FormLayout.Group>
        <Select
          id='stateDep'
          name='state'
          label={t("stores.departureState")}
          options={
            this.state.countryDep === "USA" ||
            (!this.state.countryDep &&
              actionDialogs.cellData.departure_country &&
              actionDialogs.cellData.departure_country.code === "USA")
              ? this.getStatesOption()
              : this.getGbrStatesOption()
          }
          onChange={(state) => this.setState({ stateDep: state })}
          value={
            this.state.stateDep || this.state.stateDep === ""
              ? this.state.stateDep
              : actionDialogs.cellData.departure_state &&
                actionDialogs.cellData.departure_state.code
          }
        />
        <TextField
          label={t("stores.departureZip")}
          onChange={(value) => this.setState({ zipDep: value })}
          value={
            this.state.zipDep || this.state.zipDep === ""
              ? this.state.zipDep
              : actionDialogs.cellData.departure_zip || ""
          }
        />
      </FormLayout.Group>
    );
  };

  renderUpdateSettingsActionDialog() {
    const { countries, currency, services, user, countriesSP, t } = this.props;
    const {
      actionDialogs,
      showToken,
      isEditSettings,
      isDeleteInt,
      isAddService,
      isAddPlatform,
      selectedServices,
      codeError,
      descError,
      errorCancelIntegration,
      platform,
      editingStoreSuccess,
      editingStoreError,
      intError,
      intSuccess,
      amazonSP,
      shopUrlMagento,
      accSecretMagento,
      accTokenMagento,
      clSecretMagento,
      clKeyMagento,
      keyMagento,
      shopUrlWooCom,
      keyWooCom,
    } = this.state;

    const countryArr = [
      {
        label: "",
        value: "none",
      },
    ];
    if (countries) {
      countries.map((countryItem) => {
        countryArr.push({
          label: countryItem.name_no_article,
          value: countryItem.code,
        });
      });
    }

    const currencyArr = [];
    if (currency) {
      currency.map((currencyItem) => {
        currencyArr.push({
          label: currencyItem,
          value: currencyItem,
        });
      });
    }

    const errMess = user.is_omp
      ? "Enter the project url"
      : "Enter the store url";

    const columns = [
      {
        property: "",
        header: {
          label: "No",
        },
        cell: {
          formatters: [(value, { index }) => index + 1],
        },
      },
      {
        property: "type",
        header: {
          label: "Type",
        },
      },
      {
        property: "code",
        header: {
          label: "Code",
        },
      },
      {
        property: "name",
        header: {
          label: "Description",
        },
      },
    ];

    const rows = [
      ...(actionDialogs?.cellData?.services || []),
      ...selectedServices,
    ];
    const sortedRows = formatRow(rows, columns);
   
    return (
      <Modal
        title={
          actionDialogs.cellData &&
          `${actionDialogs.cellData.website} - Settings`
        }
        infoIconVisible={false}
        onClose={() => {
          this.handleActionDialogsClose("updateSettings");
          this.resetEditDialogData();
        }}
        visible={actionDialogs.updateSettings.open}
        content={
          <>
            <br />
            {editingStoreSuccess && (
              <div>
                {/*<Banner tone='success'>*/}
                {/*  {t("stores.dataSuccessfullyChanged")}*/}
                {/*</Banner>*/}
                <BannerCustom
                status={'success'}
                message={t("stores.dataSuccessfullyChanged")}
              />
                <br />
              </div>
            )}
            {editingStoreError && (
              <div>
                {/*<Banner tone='critical'>{editingStoreError}</Banner>*/}
                <BannerCustom
                  status={'critical'}
                  message={editingStoreError}
                />
                <br />
              </div>
            )}

            {!_.isEmpty(actionDialogs.cellData) && !isEditSettings && (
              <Card
                title='Site parameters'
                style={{ textAlign: "left" }}
                sectioned
              >
                <div style={{ display: 'flex' }}>
                  <div style={{width: '50%'}}>
                    <Text fontWeight='medium'>
                      {user.is_omp
                        ? t("stores.projectName")
                        : t("stores.storeName")}
                    </Text>
                    <p>{actionDialogs.cellData.website}</p>
                  </div>
                  <div style={{width: '50%'}}>
                    <Text fontWeight='medium'>
                      {user.is_omp
                        ? t("stores.projectUrl")
                        : t("stores.storeUrl")}
                    </Text>
                    <p>{actionDialogs.cellData.website_url}</p>
                  </div>
                </div>
                <br />
                <div style={{ display: 'flex' }}>
                  <div style={{width: '50%'}}>
                    <Text fontWeight='medium'>{t("stores.platform")}</Text>
                    <p>
                      {actionDialogs.cellData.platform
                        ? actionDialogs.cellData.platform
                        : "none"}
                    </p>
                  </div>
                  <div style={{width: '50%'}}>
                    <Text fontWeight='medium'>{t("stores.status")}</Text>
                    <p>
                      <Badge
                        status={
                          actionDialogs.cellData.status === "active"
                            ? "success"
                            : "attention"
                        }
                      >
                        {actionDialogs.cellData.status &&
                          actionDialogs.cellData.status
                            .charAt(0)
                            .toUpperCase() +
                            actionDialogs.cellData.status.slice(1)}
                      </Badge>
                    </p>
                  </div>
                </div>
                <br />
                <div style={{ display: 'flex' }}>
                  <div style={{width: '50%'}}>
                    <Text fontWeight='medium'>
                      {t("stores.defaultWarehouse")}
                    </Text>
                    <p>
                      {actionDialogs.cellData.departure_country
                        ? actionDialogs.cellData.departure_country.name
                        : "none"}
                    </p>
                  </div>
                  <div style={{width: '50%'}}>
                    <Text fontWeight='medium'>{t("stores.domestic")}</Text>
                    <p>
                      {actionDialogs.cellData.country &&
                        actionDialogs.cellData.country.name}
                    </p>
                  </div>
                </div>
                <br />
                {actionDialogs.cellData.departure_country &&
                  actionDialogs.cellData.departure_country.code === "GBR" &&
                  actionDialogs.cellData.departure_state &&
                  this.renderStateAndZipInfo(
                    actionDialogs.cellData.departure_state.name,
                    actionDialogs.cellData.departure_zip
                  )}
                {actionDialogs.cellData.departure_country &&
                  actionDialogs.cellData.departure_country.code === "GBR" && (
                    <br />
                  )}

                {actionDialogs.cellData.departure_country &&
                  actionDialogs.cellData.departure_country.code === "USA" &&
                  actionDialogs.cellData.departure_state &&
                  this.renderStateAndZipInfo(
                    actionDialogs.cellData.departure_state.name,
                    actionDialogs.cellData.departure_zip
                  )}
                {actionDialogs.cellData.departure_country &&
                  actionDialogs.cellData.departure_country.code === "USA" && (
                    <br />
                  )}

                <div style={{ display: 'flex' }}>
                  <div style={{width: '50%'}}>
                    <Text fontWeight='medium'>{t("stores.integration")}</Text>
                    <p>
                      {actionDialogs.cellData.platform ? (
                        <Badge tone='success'>Active</Badge>
                      ) : (
                        "none"
                      )}
                    </p>
                  </div>

                  <div style={{width: '50%'}}>
                    <Text fontWeight='medium'>{t("stores.currency")}</Text>
                    <p>{actionDialogs.cellData.currency}</p>
                  </div>
                </div>
                <br />
                <div style={{display: 'flex', alignItems: 'center'}}>
                  <Text fontWeight='medium'>{t("stores.token")}</Text>
                  <div
                    style={{display: 'flex', alignItems: 'center', justifyContent: 'center'}}
                  >
                    <p style={{marginLeft: 10}}>
                      {showToken
                        ? actionDialogs.cellData.access_token
                        : actionDialogs.cellData.access_token &&
                          actionDialogs.cellData.access_token
                            .split("")
                            .fill("*")
                            .join("")}
                    </p>
                    <Button
                      variant={'plain'}
                      onClick={() =>
                        this.setState((prevState) => ({
                          showToken: !prevState.showToken,
                        }))
                      }
                    >
                      {showToken ? (
                        <img
                          src={eyeOffIMG}
                          alt='eyeOff'
                          style={{ marginTop: 3 }}
                        />
                      ) : (
                        <img src={eyeOnIMG} alt='eyeVisible' />
                      )}
                    </Button>
                  </div>
                </div>
              </Card>
            )}

            {!_.isEmpty(actionDialogs.cellData) && isEditSettings && (
              <Card
                title='Site parameters'
                style={{ textAlign: "left" }}
                sectioned
              >
                <FormLayout>
                  <TextField
                    label={
                      user.is_omp
                        ? t("stores.projectName")
                        : t("stores.storeName")
                    }
                    value={this.state.storeName}
                    error={
                      !this.state.storeName ? "Enter the store name" : false
                    }
                    onChange={(storeName) => this.setState({ storeName })}
                  />
                  <TextField
                    label={
                      user.is_omp
                        ? t("stores.projectUrl")
                        : t("stores.storeUrl")
                    }
                    value={this.state.storeUrl}
                    error={!this.state.storeUrl ? errMess : false}
                    onChange={(storeUrl) => this.setState({ storeUrl })}
                  />
                  <FormLayout.Group>
                    <TextField
                      label={t("stores.status")}
                      id='input-status'
                      disabled
                      value={
                        actionDialogs.cellData.status &&
                        actionDialogs.cellData.status.charAt(0).toUpperCase() +
                          actionDialogs.cellData.status.slice(1)
                      }
                    />
                    <TextField
                      label={t("stores.platform")}
                      disabled
                      value={
                        actionDialogs.cellData.platform
                          ? actionDialogs.cellData.platform
                          : "none"
                      }
                    />
                  </FormLayout.Group>
                  <FormLayout.Group>
                    <Select
                      label={t("stores.domestic")}
                      options={countryArr}
                      onChange={(value) => this.setState({ country: value })}
                      value={
                        this.state.country
                          ? this.state.country
                          : (actionDialogs.cellData.country &&
                              actionDialogs.cellData.country.code) ||
                            ""
                      }
                    />

                    <Select
                      label={t("stores.defaultWarehouse")}
                      options={countryArr}
                      onChange={(value) =>
                        this.setState({
                          countryDep: value,
                          stateDep: "",
                          zipDep: "",
                        })
                      }
                      value={
                        this.state.countryDep
                          ? this.state.countryDep
                          : (actionDialogs.cellData.departure_country &&
                              actionDialogs.cellData.departure_country.code) ||
                            ""
                      }
                    />
                  </FormLayout.Group>
                  {actionDialogs.cellData.departure_country &&
                    actionDialogs.cellData.departure_country.code === "GBR" &&
                    !this.state.countryDep &&
                    this.renderEditStateAndZip()}

                  {actionDialogs.cellData.departure_country &&
                    actionDialogs.cellData.departure_country.code === "USA" &&
                    !this.state.countryDep &&
                    this.renderEditStateAndZip()}

                  {this.state.countryDep === "GBR" &&
                    this.renderEditStateAndZip()}

                  {this.state.countryDep === "USA" &&
                    this.renderEditStateAndZip()}
                  <FormLayout.Group>
                    <div className='StoreEditLink__Wrapper'>
                      <TextField
                        label={t("stores.integration")}
                        id={
                          actionDialogs.cellData.platform
                            ? "input-status-2"
                            : "int"
                        }
                        value={
                          actionDialogs.cellData.platform ? "Active" : "none"
                        }
                        prefix={
                          <div style={{ marginTop: 5 }}>
                            {!actionDialogs.cellData.platform && (
                              <a
                                className='StoreEditLink'
                                href='#'
                                onClick={(evt) => {
                                  evt.preventDefault();
                                  this.setState({ isAddPlatform: true });
                                }}
                              >
                                <img src={plusIMG} alt='add' />
                              </a>
                            )}
                            {actionDialogs.cellData.platform &&
                              !isAddPlatform && (
                                <a
                                  className='StoreEditLink'
                                  href='#'
                                  onClick={(evt) => {
                                    evt.preventDefault();
                                    this.setState({ isDeleteInt: true });
                                  }}
                                >
                                  <img src={deleteIMG} alt='delete' />
                                </a>
                              )}
                          </div>
                        }
                      />
                    </div>
                    <Select
                      label={t("stores.currency")}
                      options={currencyArr}
                      onChange={(value) => this.setState({ currency: value })}
                      value={
                        this.state.currency
                          ? this.state.currency
                          : actionDialogs.cellData.currency || ""
                      }
                    />
                  </FormLayout.Group>
                  {isDeleteInt && !isAddPlatform && errorCancelIntegration && (
                    // <Banner tone='critical'>{errorCancelIntegration}</Banner>
                    <BannerCustom
                    status={'critical'}
                  message={errorCancelIntegration}
                />
                  )}

                  {isDeleteInt && !isAddPlatform && (
                    <ButtonGroup>
                      <Button
                        variant='primary'
                        onClick={this.doCancelIntegration}
                        tone={'critical'}
                        variant={'plain'}
                      >
                        {t("stores.delete")}
                      </Button>
                      <Button
                        onClick={() =>
                          this.setState({
                            isDeleteInt: false,
                            errorCancelIntegration: false,
                          })
                        }
                      >
                        {t("stores.cancel")}
                      </Button>
                    </ButtonGroup>
                  )}
                  <div className='StoreEditLink__Wrapper'>
                    <TextField
                      label={t("stores.token")}
                      value={
                        showToken
                          ? actionDialogs.cellData.access_token
                          : actionDialogs.cellData.access_token &&
                            actionDialogs.cellData.access_token
                              .split("")
                              .fill("*")
                              .join("")
                      }
                      prefix={
                        <div
                          className='StoreEditLink'
                          onClick={(evt) => {
                            evt.preventDefault();
                            this.setState((prevState) => ({
                              showToken: !prevState.showToken,
                            }));
                          }}
                          style={{ marginTop: 3, cursor: "pointer" }}
                        >
                          {showToken ? (
                            <img src={eyeOffIMG} alt='eyeOff' />
                          ) : (
                            <img src={eyeOnIMG} alt='eyeVisible' />
                          )}
                        </div>
                      }
                    />
                  </div>
                </FormLayout>
              </Card>
            )}
            <br />
            {/*<Card*/}
            {/*  distribution='equalSpacing'*/}
            {/*  title={t("stores.typesOfGoods")}*/}
            {/*  sectioned*/}
            {/*  actions={[*/}
            {/*    {*/}
            {/*      content: isEditSettings && (*/}
            {/*        <Button*/}
            {/*          onClick={() => this.setState({ isAddService: true })}*/}
            {/*        >*/}
            {/*          {t("stores.addNew")}*/}
            {/*        </Button>*/}
            {/*      ),*/}
            {/*    },*/}
            {/*  ]}*/}
            {/*>*/}
            {/*  {!_.isEmpty(actionDialogs.cellData) && (*/}
            {/*    <Card>*/}
            {/*      <DataTable*/}
            {/*        columnContentTypes={["text", "text", "text", "text"]}*/}
            {/*        headings={columns.map(({ header }) => (*/}
            {/*          <Text fontWeight='semibold'>{header.label}</Text>*/}
            {/*        ))}*/}
            {/*        rows={sortedRows}*/}
            {/*        hideScrollIndicator*/}
            {/*        increasedTableDensity*/}
            {/*      />*/}
            {/*      {!(*/}
            {/*        !_.isEmpty(actionDialogs.cellData.services) ||*/}
            {/*        !_.isEmpty(selectedServices)*/}
            {/*      ) && (*/}
            {/*        <NoDataMessage*/}
            {/*          title={t("stores.typesOfGoodsNotSelected")}*/}
            {/*          description={t("stores.selectTypesOfGoods")}*/}
            {/*          // buttonText={t("stores.select")}*/}
            {/*          style={{ height: "auto", padding: "0.2rem 1rem" }}*/}
            {/*          onClick={() => {}}*/}
            {/*        />*/}
            {/*      )}*/}
            {/*    </Card>*/}
            {/*  )}*/}
            {/*</Card>*/}
            
            <div className="block-white">
              <BlockStack gap="400">
                {/* Заголовок и кнопка справа */}
                <InlineStack align="space-between">
                  <Text as="h2" variant="headingSm">
                    {t("stores.typesOfGoods")}
                  </Text>
                  
                  {isEditSettings && (
                    <Button variant={'primary'} size={'micro'} onClick={() => this.setState({ isAddService: true })}>
                      {t("stores.addNew")}
                    </Button>
                  )}
                </InlineStack>
            
                <Divider />
                
                {/* Вложенная таблица, если есть данные */}
                {!_.isEmpty(actionDialogs.cellData) && (
                  <div className="block-white">
                    <BlockStack gap="400">
                      <DataTable
                        columnContentTypes={["text", "text", "text", "text"]}
                        headings={columns.map(({ header }) => (
                          <Text fontWeight="semibold">{header.label}</Text>
                        ))}
                        rows={sortedRows}
                        hideScrollIndicator
                        increasedTableDensity
                      />
                      
                      {!(
                        !_.isEmpty(actionDialogs.cellData.services) ||
                        !_.isEmpty(selectedServices)
                      ) && (
                        <NoDataMessage
                          title={t("stores.typesOfGoodsNotSelected")}
                          description={t("stores.selectTypesOfGoods")}
                          buttonText={isEditSettings ? t("stores.select"): null}
                          style={{ height: "auto", padding: "0.2rem 1rem" }}
                          onClick={() => {
                            if (isEditSettings) {
                              this.setState({ isAddService: true })
                            }
                          }}
                        />
                      )}
                    </BlockStack>
                  </div>
                )}
              </BlockStack>
            </div>
            
            {!_.isEmpty(actionDialogs.cellData) && isAddService && (
              <div className='block-white'>
                <Text fontWeight='medium'>{t("stores.addNewGood")}</Text>
                <FormLayout>
                  <FormLayout.Group>
                    <Select
                      label={t("stores.type")}
                      options={[
                        { label: "", value: "" },
                        { label: "Service", value: "service" },
                        { label: "Good", value: "good" },
                      ]}
                      onChange={(type) => this.setState({ type })}
                      value={this.state.type || ""}
                    />
                    {!this.state.type && <br />}
                    {this.state.type === "good" && (
                      <TextField
                        label={t("stores.code")}
                        value={this.state.code}
                        onChange={(code) =>
                          this.setState({ code, codeError: false })
                        }
                        error={codeError}
                      />
                    )}
                    {this.state.type === "service" && (
                      <Select
                        label={t("stores.desc")}
                        options={services.map((srv) => {
                          return {
                            label: srv.name,
                            value: srv.code,
                          };
                        })}
                        value={this.state.code || services[0].code || ""}
                        onChange={(value) => this.setState({ code: value })}
                      />
                    )}
                  </FormLayout.Group>
                  {this.state.type === "good" && (
                    <TextField
                      label={t("stores.desc")}
                      value={this.state.description}
                      onChange={(description) =>
                        this.setState({ description, descError: false })
                      }
                      error={descError}
                    />
                  )}
                </FormLayout>
                <br />
                <ButtonGroup>
                  <Button
                    variant='primary'
                    size={'micro'}
                    onClick={this.doAddService}
                    disabled={!this.state.type}
                  >
                    {t("stores.addNew")}
                  </Button>
                  <Button
                    size={'micro'}
                    onClick={() =>
                      this.setState({
                        isAddService: false,
                        type: false,
                        code: "",
                        codeError: false,
                        description: "",
                        descError: false,
                      })
                    }
                  >
                    {t("stores.cancel")}
                  </Button>
                </ButtonGroup>
              </div>
            )}

            {!_.isEmpty(actionDialogs.cellData) &&
              isEditSettings &&
              isAddPlatform && (
                <Card sectioned title={t("addStore.availableWS")}>
                  <Platforms
                    platform={platform}
                    handleChangePlatform={(platform) =>
                      this.setState({
                        platform,
                        amazonSP: [],
                        shopUrlMagento: "",
                        accSecretMagento: "",
                        accTokenMagento: "",
                        clSecretMagento: "",
                        clKeyMagento: "",
                        keyMagento: "",
                        shopUrlWooCom: "",
                        keyWooCom: "",
                      })
                    }
                  />
                </Card>
              )}

            {!_.isEmpty(actionDialogs.cellData) &&
              isEditSettings &&
              isAddPlatform && (
                <Card sectioned>
                  {intError && (
                    <div>
                      {/*<Banner*/}
                      {/*  tone='critical'*/}
                      {/*  onDismiss={() => this.setState({ intError: false })}*/}
                      {/*>*/}
                      {/*  {intError}*/}
                      {/*</Banner>*/}
                      <BannerCustom
                        status={'critical'}
                        message={intError}
                        onDismiss={() => this.setState({ intError: false })}
                      />
                      <br />
                    </div>
                  )}

                  {intSuccess && (
                    <div>
                      {/*<Banner*/}
                      {/*  tone='success'*/}
                      {/*  onDismiss={() => this.setState({ intSuccess: false })}*/}
                      {/*>*/}
                      {/*  {t("stores.integrationWasSuccessfull")}*/}
                      {/*</Banner>*/}
                      <BannerCustom
                        status={'success'}
                        message={t("stores.integrationWasSuccessfull")}
                        onDismiss={() => this.setState({ intSuccess: false })}
                      />
                      <br />
                    </div>
                  )}

                  {platform === "shopify" && (
                    <Button
                      variant='primary'
                      disabled={this.getDisabledStatus()}
                      onClick={() =>
                        window.location.replace(
                          "https://apps.shopify.com/vatcompliance"
                        )
                      }
                    >
                      {user.is_omp
                        ? t("stores.connectProject")
                        : t("stores.connectStore")}
                    </Button>
                  )}

                  {platform === "amazon" && (
                    <FormLayout>
                      <FormLayout.Group>
                        <ChoiceList
                          title={t("stores.sellerCentralCountry")}
                          allowMultiple
                          choices={countriesSP.map((country) => ({
                            label: country,
                            value: country,
                          }))}
                          selected={amazonSP}
                          onChange={(value) =>
                            this.setState({ amazonSP: value })
                          }
                        />
                      </FormLayout.Group>
                      <br />
                    </FormLayout>
                  )}

                  {platform === "magento1" && (
                    <FormLayout>
                      <FormLayout.Group>
                        <TextField
                          label={
                            user.is_omp
                              ? t("stores.projectUrl")
                              : t("stores.storeUrl")
                          }
                          value={shopUrlMagento || ""}
                          onChange={(url) =>
                            this.setState({ shopUrlMagento: url })
                          }
                        />
                        <TextField
                          label={t("stores.apiKey")}
                          value={keyMagento || ""}
                          onChange={(key) => this.setState({ keyMagento: key })}
                        />
                      </FormLayout.Group>
                      <br />
                    </FormLayout>
                  )}

                  {platform === "magento" && (
                    <FormLayout>
                      <TextField
                        label={
                          user.is_omp
                            ? t("stores.projectUrl")
                            : t("stores.storeUrl")
                        }
                        value={shopUrlMagento || ""}
                        onChange={(url) =>
                          this.setState({ shopUrlMagento: url })
                        }
                      />
                      <FormLayout.Group>
                        <TextField
                          label={t("stores.accessSecret")}
                          value={accSecretMagento || ""}
                          onChange={(secret) =>
                            this.setState({ accSecretMagento: secret })
                          }
                        />
                        <TextField
                          label={t("stores.accessToken")}
                          value={accTokenMagento}
                          onChange={(token) =>
                            this.setState({ accTokenMagento: token })
                          }
                        />
                      </FormLayout.Group>
                      <FormLayout.Group>
                        <TextField
                          label={t("stores.clientSecret")}
                          value={clSecretMagento}
                          onChange={(secret) =>
                            this.setState({ clSecretMagento: secret })
                          }
                        />
                        <TextField
                          label={t("stores.clientKey")}
                          value={clKeyMagento || ""}
                          onChange={(key) =>
                            this.setState({ clKeyMagento: key })
                          }
                        />
                      </FormLayout.Group>
                      <br />
                    </FormLayout>
                  )}

                  {platform === "woocommerce" && (
                    <>
                      <FormLayout>
                        <TextField
                          label={
                            user.is_omp
                              ? t("stores.projectUrl")
                              : t("stores.storeUrl")
                          }
                          value={shopUrlWooCom || ""}
                          onChange={(url) =>
                            this.setState({ shopUrlWooCom: url })
                          }
                        />
                        <FormLayout.Group>
                          <TextField
                            label={t("stores.apiKey")}
                            value={keyWooCom}
                            onChange={(key) =>
                              this.setState({ keyWooCom: key })
                            }
                          />
                        </FormLayout.Group>
                      </FormLayout>
                      <br />
                    </>
                  )}

                  {platform !== "shopify" && (
                    <ButtonGroup>
                      <Button
                        variant='primary'
                        onClick={this.doIntegrate}
                        disabled={this.getDisabledStatus()}
                      >
                        {user.is_omp
                          ? t("stores.connectProject")
                          : t("stores.connectStore")}
                      </Button>
                      <Button
                        onClick={() =>
                          this.setState({
                            isAddPlatform: false,
                            amazonSP: [],
                            shopUrlMagento: "",
                            accSecretMagento: "",
                            accTokenMagento: "",
                            clSecretMagento: "",
                            clKeyMagento: "",
                            keyMagento: "",
                            shopUrlWooCom: "",
                            keyWooCom: "",
                          })
                        }
                      >
                        {t("stores.cancelIntegration")}
                      </Button>
                    </ButtonGroup>
                  )}
                </Card>
              )}
          </>
        }
        footer={
          isEditSettings ? (
            <ButtonGroup>
              <Button
                variant='primary'
                disabled={isAddService}
                onClick={this.doEditStore}
              >
                {t("stores.saveChanges")}
              </Button>
              <Button
                disabled={isAddService}
                onClick={() => {
                  this.handleActionDialogsClose("updateSettings");
                  this.resetEditDialogData();
                }}
              >
                {t("stores.discard")}
              </Button>
            </ButtonGroup>
          ) : (
            <ButtonGroup>
              <Button
                onClick={() =>
                  this.setState({ isEditSettings: true, showToken: false })
                }
              >
                {t("stores.editSettings")}
              </Button>
            </ButtonGroup>
          )
        }
      />
    );
  }

  renderActivateActionDialog() {
    const { actionDialogs } = this.state;
    const { t } = this.props;

    const onClose = () => this.handleActionDialogsClose("activate");

    return (
      <Modal
        title={t("stores.activate")}
        contentOnCenter
        disabled={this.props.isActivating}
        onClose={onClose}
        iconType='warning'
        visible={actionDialogs.activate.open}
        description={
          <div style={{ maxWidth: "450px", margin: "0 auto" }}>
            {t("stores.removedTest")}?
          </div>
        }
        footer={
          <ButtonGroup fullWidth>
            <Button onClick={onClose} size='large'>
              {t("stores.cancel")}
            </Button>
            <Button variant='primary' onClick={this.doActivate} size='large'>
              {this.props.isActivating
                ? t("stores.activating")
                : "\u00A0\u00A0\u00A0\u00A0Ok\u00A0\u00A0\u00A0\u00A0"}
            </Button>
          </ButtonGroup>
        }
      />
    );
  }

  renderAccessTokenActionDialog() {
    const { actionDialogs } = this.state;
    const { t } = this.props;

    return (
      <Modal
        title={`${t("stores.token")}: ${
          actionDialogs.cellData ? actionDialogs.cellData.website : ""
        }`}
        onClose={() => this.handleActionDialogsClose("accessToken")}
        visible={actionDialogs.accessToken.open}
        footer={
          <ButtonGroup>
            <Button variant='primary' onClick={this.handleResetTokenOpen}>
              {t("stores.reset")}
            </Button>
            <Button
              onClick={() => this.handleActionDialogsClose("accessToken")}
            >
              {t("stores.cancel")}
            </Button>
          </ButtonGroup>
        }
      >
        {actionDialogs.cellData && actionDialogs.cellData.access_token ? (
          <p>{actionDialogs.cellData.access_token}</p>
        ) : (
          <p>
            {t("stores.contact")}{" "}
            <Link onClick={() => this.props.navigate("/support")}>
              {t("stores.supportTeam")}
            </Link>
            {t("stores.dot")}
          </p>
        )}
      </Modal>
    );
  }

  renderResetTokenActionDialog() {
    const { actionDialogs } = this.state;
    const { t } = this.props;

    return (
      <Modal
        title={`${t("stores.resetToken")}?`}
        onClose={() => this.handleActionDialogsClose("resetToken")}
        visible={actionDialogs.resetToken.open}
        footer={
          <ButtonGroup>
            <Button
              variant='primary'
              disabled={this.props.isReseting}
              onClick={this.doResetToken}
            >
              {this.props.isReseting
                ? t("stores.reseting")
                : "\u00A0\u00A0\u00A0\u00A0Ok\u00A0\u00A0\u00A0\u00A0"}
            </Button>
            <Button onClick={() => this.handleActionDialogsClose("resetToken")}>
              {t("stores.cancel")}
            </Button>
          </ButtonGroup>
        }
      >
        <p>
          {t("stores.oldToken")}
          {t("stores.dot")}
        </p>

        {(_.isObject(this.props.resetingError) ||
          _.isArray(this.props.resetingError)) && (
          <div>
            {_.map(this.props.resetingError.website, (val, key) => {
              return (
                <div key={key}>
                  <Text variant="bodyMd" color="critical">
                    {val}
                  </Text>
                </div>
              );
            })}
          </div>
        )}
        {_.isString(this.props.resetingError) && (
          <div>
            <Text variant="bodyMd" color="critical">
              {this.props.resetingError}
            </Text>
          </div>
        )}
      </Modal>
    );
  }

  updatedRows = (rows) =>
    rows.map((e) => {
      if (e.platform) {
        return { ...e, updating: this.state.updating };
      }
      return e;
    });

  render() {
    const { user, userGuide, fetchingWebsites, t } = this.props;
    const { pagination } = this.state;

    const rows = this.updatedRows(this.props.websites);

    const columns = [
      {
        property: "website",
        header: {
          label: user.is_omp ? t("stores.project") : t("stores.store"),
        },
        cell: {
          formatters: [
            (value) => (
              <div style={{ maxWidth: "270px",
                overflow: 'hidden',
                textOverflow: 'ellipsis' }}>
                {value ? value.match(/.{1,30}/g).join(" ") : ""}
              </div>
            ),
          ],
        },
      },
      {
        property: "website_url",
        header: {
          label: user.is_omp ? t("stores.projectUrl") : t("stores.storeUrl"),
        },
        cell: {
          formatters: [
            (value) => (
              <div style={{ maxWidth: "270px", overflow: 'hidden',
                textOverflow: 'ellipsis' }}>
                {value ? value.match(/.{1,30}/g).join(" ") : ""}
              </div>
            ),
          ],
        },
      },
      {
        property: "country",
        header: {
          label: t("stores.domestic"),
        },
        cell: {
          formatters: [(value) => value.name],
        },
      },
      {
        property: "status",
        header: {
          label: t("stores.status"),
        },
        cell: {
          formatters: [
            (value, { rowData }) => {
              let platformNameToUpper = "";
              if (rowData.platform) {
                if (rowData.platform === "ebay") {
                  platformNameToUpper = "eBay";
                } else {
                  platformNameToUpper =
                    rowData.platform.charAt(0).toUpperCase() +
                    rowData.platform.slice(1);
                }
              }

              return (
                <div style={{display: 'flex', alignItems: 'center', justifyContent: 'flex-start'}}
                >
                  {this.props.user.is_manager && (
                    <Tooltip content='Change status'>
                      <Button
                        variant={'plain'}
                        onClick={() =>
                          this.handleActionDialogsOpen("status", rowData)
                        }
                      >
                        <img
                          src={settingsIMG}
                          alt='edit'
                          style={{ marginRight: "0.75rem" }}
                        />
                      </Button>
                    </Tooltip>
                  )}
                  <Badge tone={value === "active" ? "success" : "attention"}>
                    {!rowData.platform &&
                      value.toString()[0].toUpperCase() +
                        value.toString().substr(1).split("_")[0]}
                    {rowData.platform &&
                      value !== "active" &&
                      value.toString()[0].toUpperCase() +
                        value.toString().substr(1).split("_")[0]}
                    {rowData.platform &&
                      value === "active" &&
                      value.toString()[0].toUpperCase() +
                        value.toString().substr(1).split("_")[0] +
                        ` integrated with ${platformNameToUpper}`}
                  </Badge>
                  {value === "pending_auth" && (
                    <InfoTooltip
                      content={
                        user.is_omp
                          ? t("stores.approvalOfProject")
                          : t("stores.approvalOfStore")
                      }
                    />
                  )}
                </div>
              );
            },
          ],
        },
      },
      {
        property: "id",
        header: {
          label: "" /*'Actions'*/,
          transforms: [],
        },
        cell: {
          formatters: [
            (value, { rowData }) => {
              const renderSettings = () => (
                <Tooltip content={t("stores.setting")}>
                  <Button
                    variant={'plain'}
                    onClick={() =>
                      this.handleActionDialogsOpen("updateSettings", rowData)
                    }
                  >
                    <img src={settingsIMG} alt='settings' />
                  </Button>
                </Tooltip>
              );

              return (
                <div>
                  {rowData.status === "ready" && (
                    <Tooltip content={t("stores.activate")}>
                      <Button
                        variant={'plain'}
                        onClick={() =>
                          this.handleActionDialogsOpen("activate", rowData)
                        }
                      >
                        <img
                          src={activateIMG}
                          alt='activate'
                          style={{ marginRight: "0.2rem" }}
                        />
                      </Button>
                    </Tooltip>
                  )}

                  {((!user.is_manager &&
                    !user.is_omp &&
                    rowData.status === "active") ||
                    user.is_manager ||
                    user.is_omp) &&
                    renderSettings()}
                </div>
              );
            },
          ],
        },
      },
      {
        property: "platform",
        header: {
          label: "" /*'Platform actions'*/,
          transforms: [],
        },
        cell: {
          formatters: [
            (value, { rowData }) => (
              <span
                style={{
                  display: "flex",
                  gap: "1rem",
                  alignItems: "center",
                }}
              >
                <Tooltip content={t("stores.update")}>
                  <Link
                    className='Polaris-Link'
                    onClick={() => this.props.navigate("/transactions/import")}
                  >
                    <img src={uploadCloudIMG} alt='import' />
                  </Link>
                </Tooltip>

                {this.props.user.is_manager && (
                  <Tooltip content={t("stores.del")}>
                    <Button
                      variant={'plain'}
                      onClick={() =>
                        this.handleActionDialogsOpen("delete", rowData)
                      }
                    >
                      <img src={deleteIMG} alt='delete' />
                    </Button>
                  </Tooltip>
                )}
              </span>
            ),
          ],
        },
      },
    ];

    const sortedRows = formatRow(rows, columns);
    const paginatedRows = paginate(pagination)(sortedRows);

    return (
      <Page
        separator
        fullWidth
        title={
          <Text variant='heading3xl' as='span'>
            {t("stores.stores")}
          </Text>
        }
        subtitle={
          <Text variant='bodyLg' as='span' color='subdued'>
            {t("stores.hereYouCanManage")}
          </Text>
        }
        primaryAction={
          <div
            style={{
              position: "absolute",
              right: 0,
              top: "50%",
              transform: "translateY(-50%)",
            }}
          >
            <Button icon={AddIcon} variant='primary' onClick={() => this.props.navigate("/stores/add")}>
              {user.is_omp ? t("stores.addProject") : t("stores.addStore")}
              
            </Button>
          </div>
        }
      >
        <PageHelmet title={"Stores"} />

        {this.props.errorList.length > 0 &&
          this.props.errorList.map((errorItem, ind) => (
            <div key={`${ind}err`}>
              <Banner
                title={
                  user.is_omp
                    ? "Error adding project"
                    : t("stores.addStoreError")
                }
                onDismiss={() => {
                  this.props
                    .resetSystemMessage({
                      modelsName: `sites`,
                      id: errorItem.id,
                    })
                    .then(() => this.props.fetchWebsitesErrorList())
                    .then(() => this.props.fetchNewMessagesCount())
                    .catch(_.noop);
                }}
                tone='critical'
              >
                {user.is_omp ? t("stores.specProject") : t("stores.specStore")}{" "}
                {errorItem.website_name} {t("stores.exists")}
              </Banner>
              <br />
            </div>
          ))}

        <GuideCompletionPopup
          title={t("userGuide.completed2")}
          description={t("userGuide.completed2_desc")}
          visible={
            userGuide &&
            userGuide.show_user_guide &&
            userGuide.steps[1].show_banner
          }
          step={2}
        />

        <Card>
          {fetchingWebsites ? (
            <div>
              <Spinner />
            </div>
          ) : (
            <>
              
              <DataTable
                columnContentTypes={[
                  "text",
                  "text",
                  "text",
                  "text",
                  "text",
                  "text",
                ]}
                headings={columns.map(({ header }) => (
                  <Text fontWeight='semibold'>{header.label}</Text>
                ))}
                rows={paginatedRows.rows}
              />
              {_.isEmpty(sortedRows) && (
                <NoDataMessage
                  description={
                    user.is_omp
                      ? t("stores.needAddProject")
                      : t("stores.needAddStore")
                  }
                  buttonText={t("stores.addStore")}
                  onClick={() => this.props.navigate("/stores/add")}
                />
              )}
            </>
          )}
        </Card>

        {this.renderUpdateSettingsActionDialog()}
        {this.renderAccessTokenActionDialog()}
        {this.renderResetTokenActionDialog()}
        {this.renderActivateActionDialog()}
        {this.props.user.is_manager && this.renderChangeStatusDialog()}
        {this.props.user.is_manager && this.renderDeleteStoreDialog()}

        {!_.isEmpty(sortedRows) && (
          <Box padding='4'>
            <Pagination
              total={this.props.websites.length}
              current={pagination.page}
              pageSize={defaultLimit}
              onChange={(current) => this.onSelectPage(current)}
            />
          </Box>
        )}
      </Page>
    );
  }
}

const mapStateToProps = (state) => ({
  user: state.user.user,
  userGuide: state.user.userGuide,
  countries: state.other.countries,
  currency: state.other.currency,
  listStoreStatus: state.manager.listStoreStatus,
  websites: state.websites.websites,
  fetchingWebsites: state.websites.fetching,
  services: state.other.services,
  storeStatus: state.manager.storeStatus,
  errorList: state.websites.errorList,
  isReseting: state.websites.reseting,
  resetingError: state.websites.resetingError,
  isUploading: state.websites.uploading,
  uploadingError: state.websites.uploadingError,
  isActivating: state.websites.activating,
  activatingError: state.websites.activatingError,
  defaultLanguage: state.user.defaultLanguage,
  usStates: state.taxSettings.usStates,
  gbrStates: state.taxSettings.gbrStates,
  countriesSP: state.websites.countriesSP,
  fetchingSP: state.websites.fetchingSP,
});

const mapDispatchToProps = (defaultDispatch) => {
  const dispatch = createCustomDispatch(defaultDispatch);

  return {
    fetchUserGuide: () => dispatch(fetchUserGuide()),
    fetchWebsites: () => dispatch(fetchWebsites()),
    fetchCountries: () => dispatch(fetchCountries()),
    fetchCurrency: () => dispatch(fetchCurrency()),
    fetchServices: () => dispatch(fetchServices()),
    fetchListStoreStatus: () => dispatch(fetchListStoreStatus()),
    fetchWebsitesErrorList: () => dispatch(fetchWebsitesErrorList()),
    fetchCurrentPlan: () => dispatch(fetchCurrentPlan()),
    fetchStates: () => dispatch(fetchStates()),
    fetchGbrStates: () => dispatch(fetchGbrStates()),
    resetWebsiteToken: (params) => dispatch(resetWebsiteToken(params)),
    activateWebsite: (params) => dispatch(activateWebsite(params)),
    updateTransactionsIntegration: (params) =>
      dispatch(updateTransactionsIntegration(params)),
    resetSystemMessage: (params) => dispatch(resetSystemMessage(params)),
    fetchNewMessagesCount: () => dispatch(fetchNewMessagesCount()),
    editStoreStatus: (params) => dispatch(editStoreStatus(params)),
    deleteStore: (params) => dispatch(deleteStore(params)),
    editStore: (params) => dispatch(editStore(params)),
    cancelIntegration: (params) => dispatch(cancelIntegration(params)),
    integrateShopify: (params) => dispatch(integrateShopify(params)),
    integrateEtsy: (data) => dispatch(integrateEtsy(data)),
    integrateEbay: (data) => dispatch(integrateEbay(data)),
    integrateAmazon: (data) => dispatch(integrateAmazon(data)),
    fetchAmazonSPCountries: () => dispatch(fetchAmazonSPCountries()),
    integrateWooCom: (data) => dispatch(integrateWooCom(data)),
    integrateMagento: (data) => dispatch(integrateMagento(data)),
    integrateMagento2: (data) => dispatch(integrateMagento2(data)),
    integrateStripe: (data) => dispatch(integrateStripe(data)),
  };
};

export default withTranslation()(
  connect(mapStateToProps, mapDispatchToProps)(withRouter(Stores))
);
