import { Button, ButtonGroup, Card, DataTable, Layout, Page, Spinner, Tabs, Text } from '@shopify/polaris'
import React, { useEffect, useState } from 'react'
import { useSelector } from 'react-redux'
import NoDataMessage from '../../components/NoDataMessage'
import useSelectedRows from '../../containers/Documents/tabs/Invoices/hooks/useSelectedRows'
import useAppDispatch from '../../hooks/useAppDispatch'
import { fetchEDocuments } from '../../redux/features/e-documents/edocumentsSlice'
import EDocumentsTable from './EDocumentsTable'
import useTabsForEDocuments from './useTabsForEDocuments'

const EDocuments = () => {
	const {edocuments, fetchingEDocuments} = useSelector(state => state.edocuments)
	const [invoiceTypeChange, setInvoiceTypeChange] = useState('all')
	const {tabs, selectedTab, handleTabSelect} = useTabsForEDocuments()
	const {selectedRows, handleSelectAll, handleRowSelect} =
		useSelectedRows(edocuments)
	const [query, setQuery] = useState({
		invoice_type: 'all',
		type: "vat_invoice",
		offset: 0,
		limit: 25
	})
	const dispatch = useAppDispatch()
	
	const req = async () => {
		try {
			await dispatch(fetchEDocuments(query))
		}catch (e) {
			console.error('error', e)
		}
	}
	
	useEffect(() => {
		req()
	}, [query])
	
	useEffect(() => {
		setQuery((prev) => ({
			...prev,
			invoice_type: invoiceTypeChange,
			type: selectedTab === 0 ? "vat_invoice" : "sales_tax_invoice",
			offset: 0,
		}))
	}, [invoiceTypeChange, selectedTab])
	
	const invoiceTypeChangeHandler = (type) => {
		setInvoiceTypeChange(type)
	}

	
	useEffect(() => {
		const savedTab = sessionStorage.getItem('selectedTab')
		if (savedTab !== null) {
			handleTabSelect(Number(savedTab))
		}
	}, [handleTabSelect])
	
	const handleTabSelectWithStorage = (tab) => {
		handleTabSelect(tab)
		sessionStorage.setItem('selectedTab', tab)
	}
	return (
		<Page
			separator
			fullWidth
			title={
				<Text
					variant='heading3xl'
					as='span'
				>
          E-Documents
        </Text >
			}
			subtitle={
				<Text
					variant='bodyLg'
					as='span'
					color='subdued'
				>
          Simplify your financial documentation with our ready-made templates. Choose template, input your data, and generate your document in seconds.
        </Text >
			}
		>
			<Layout.Section >
          <Tabs
	          tabs={tabs}
	          selected={selectedTab}
	          onSelect={(tab) => handleTabSelectWithStorage(tab)}
          />
        </Layout.Section >
			
			<div style={{marginTop: 15, marginBottom: 15}}>
				<Layout.Section >
					<ButtonGroup segmented>
          <Button
	          variant={invoiceTypeChange === 'all' ? 'primary' : ''}
	          onClick={() => invoiceTypeChangeHandler('all')}
          >
            All
          </Button >
          <Button
	          variant={invoiceTypeChange === 'invoice' ? 'primary' : ''}
	          onClick={() => invoiceTypeChangeHandler('invoice')}
          >
            Invoices
          </Button >
          <Button
	          variant={invoiceTypeChange === 'bill' ? 'primary' : ''}
	          onClick={() => invoiceTypeChangeHandler('bill')}
          >
            Bills
          </Button >
          <Button
	          variant={invoiceTypeChange === 'credit_note' ? 'primary' : ''}
	          onClick={() => invoiceTypeChangeHandler('credit_note')}
          >
           Credit Notes
          </Button >
        </ButtonGroup >
				</Layout.Section >
			</div >
			
			<EDocumentsTable
				edocuments={edocuments}
				loading={fetchingEDocuments}
				selectedRows={selectedRows}
				handleSelectAll={handleSelectAll}
				handleRowSelect={handleRowSelect}
			/>
			
		</Page >
	)
}

export default EDocuments