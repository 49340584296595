import { combineReducers } from "@reduxjs/toolkit"
import authReducer from "./auth/authSlice"
import edocumentsReducer from './e-documents/edocumentsSlice'
import userReducer from "./user"
import subscriptionReducer from "./subscription"
import messengerReducer from "./messenger"
import otherReducer from "./other"
import managerReducer from "./manager"
import taxReducer from "./tax"
import dashboardReducer from "./dashboard"
import declarationsReducre from "./declarations"
import dueDatesReducer from "./dueDates"
import profileReducer from "./profile"
import thresholdsReducer from "./thresholds"
import transactionsReducer from "./transactions"
import registrationReducer from "./registration"
import messagesReducer from "./messages"
import taxSettingsReducer from "./tax-settings"
import merchSettingsReducer from "./merch-settings"
import websitesReducer from "./websites"
import paymentReducer from "./payment"
import documentsReducer from "./documents"
import ukSettingsReducer from "./uk-settings"
import lookUpReducer from "./look-up"
import customersReducer from "./customers"

const rootReducer = combineReducers({
	auth: authReducer,
	user: userReducer,
	subscription: subscriptionReducer,
	messenger: messengerReducer,
	other: otherReducer,
	manager: managerReducer,
	tax: taxReducer,
	dashboard: dashboardReducer,
	declarations: declarationsReducre,
	dueDates: dueDatesReducer,
	profile: profileReducer,
	thresholds: thresholdsReducer,
	transactions: transactionsReducer,
	vatRegistration: registrationReducer,
	messages: messagesReducer,
	taxSettings: taxSettingsReducer,
	merchSettings: merchSettingsReducer,
	websites: websitesReducer,
	payment: paymentReducer,
	documents: documentsReducer,
	edocuments: edocumentsReducer,
	ukSettings: ukSettingsReducer,
	lookUp: lookUpReducer,
	customers: customersReducer
})

export default rootReducer
