import { Layout, Page, Text } from '@shopify/polaris'
import React from 'react'
import LanguageForPdFs from './LanguageForPDFs/LanguageForPDFs'
import PeppolNumberComponent from './PeppolBlock/PeppolNumberComponent'

const Settings = () => {
	return (
		<Page
			separator
			fullWidth
			title={
				<Text
					variant='heading2xl'
					as='span'
				>
          Settings for invoicing
        </Text >
			}
			subtitle={
				<Text
					variant='bodyLg'
					as='span'
					color='subdued'
				>
        Here you will find settings
        </Text >
			}
		>
			<Layout >
	      <Layout.Section >
		      
		      <PeppolNumberComponent />
		      <br/>
		      <br/>
		      <LanguageForPdFs />
		      
	      </Layout.Section >
			</Layout >
		</Page >
	)
}

export default Settings