import { Box, Button, ButtonGroup, Card } from '@shopify/polaris'
import React, { useEffect, useState } from 'react'
import { useSelector } from 'react-redux'
import BannerCustom from '../../../components/Banners/BannerCustom'
import useAppDispatch from '../../../hooks/useAppDispatch'
import {
	fetchGetPeppolNumber,
	fetchPeppolCountriesList,
	fetchPeppolDataRegistration
} from '../../../redux/features/look-up/lookUpSlice'
import DeleteModalForSettings from '../DeleteModalForSettings/DeleteModalForSettings'
import AddPeppolBlock from './AddPeppolBlock'
import ApplyPeppolBlock from './ApplyPeppolBlock'
import TablePeppol from './TablePeppol'

const PeppolNumberComponent = () => {
	const {user} = useSelector((state) => state.user)
	const {countries_list, data_registration, peppol_number_list, registrationMessage, fetchingGetPeppolNumber} = useSelector(state => state.lookUp)
	const [addPeppolNumberShowBlock, setAddPeppolNumberShowBlock] = useState(false)
	const [applyPeppolNumberShowBlock, setApplyPeppolNumberShowBlock] = useState(false)
	const [editPeppolNumberValue, setEditPeppolNumberValue] = useState({})
	const [showDeleteModal, setShowDeleteModal] = useState(false)
	const dispatch = useAppDispatch()
	
	const toggleAddPeppolNumberShowBlock = () => {
		setAddPeppolNumberShowBlock(!addPeppolNumberShowBlock)
		setApplyPeppolNumberShowBlock(false)
		addPeppolNumberShowBlock === false && setEditPeppolNumberValue({})
	}
	
	const toggleApplyPeppolNumberShowBlock = () => {
		setApplyPeppolNumberShowBlock(!applyPeppolNumberShowBlock)
		setAddPeppolNumberShowBlock(false)
	}

	const reUpdateData = async () => {
		await dispatch(fetchGetPeppolNumber())
	}
	
	useEffect(() => {
		const getData = async () => {
			try {
				await dispatch(fetchGetPeppolNumber())
				await dispatch(fetchPeppolCountriesList())
				await dispatch(fetchPeppolDataRegistration())
			} catch (e) {
				console.error(e)
			}
		}
		getData()
	}, [])
	
	useEffect(() => {
		if (registrationMessage && !registrationMessage?.error){
			const timer = () => setTimeout(() => dispatch({type: 'CLEAR_REGISTRATION_MESSAGE'}), 8000)
			timer()
		}
	}, [registrationMessage])
	
	const onEditHandler = (item) => {
		setAddPeppolNumberShowBlock(true)
		setApplyPeppolNumberShowBlock(false)
		setEditPeppolNumberValue(item)
	}
	const onDeleteHandler = (item) => {
		setShowDeleteModal(true)
		setEditPeppolNumberValue({...item, company_name: user?.company?.name})
	}
	
	const onCloseDeleteModalHandler = () => {
		setShowDeleteModal(false)
	}
	
	return (
		<Card >
			{
				registrationMessage && !registrationMessage?.error
				&&
				<BannerCustom
					status={'success'}
					message={registrationMessage?.["Peppol ID"]}
				/>
			}
			{
				showDeleteModal
				&&
				<DeleteModalForSettings
					show={showDeleteModal}
					onClose={onCloseDeleteModalHandler}
					data={editPeppolNumberValue}
					block={'peppolBlock'}
					reUpdateData={reUpdateData}
				/>
			}
			<div >
	      <Box
		      display='flex'
		      flexDirection='column'
		      gap='5'
		      width='100%'
	      >
		      <ButtonGroup >
	          <Button
		          variant='primary'
		          size={'micro'}
		          onClick={toggleAddPeppolNumberShowBlock}
	          >
	            Add peppol number
	          </Button >
	          <Button
		          variant='secondary'
		          size={'micro'}
		          onClick={toggleApplyPeppolNumberShowBlock}
	          >
	            Apply for peppol number
	          </Button >
	        </ButtonGroup >
		      
		      {addPeppolNumberShowBlock
			      &&
			      <AddPeppolBlock
				      countries={countries_list}
				      editData={editPeppolNumberValue}
				      onHide={toggleAddPeppolNumberShowBlock}
				      reUpdateData={reUpdateData}
			      />}
		      
		      {applyPeppolNumberShowBlock
			      &&
			      <ApplyPeppolBlock
				      countries={countries_list}
				      dataInfo={data_registration}
				      onHide={toggleApplyPeppolNumberShowBlock}
			      />
		      }
		      
		      <div style={{display: 'flex', marginTop: 30, width: '100%'}}>
	          <TablePeppol
	            dataTable={peppol_number_list}
	            loadingTable={fetchingGetPeppolNumber}
	            user={user}
	            onEdit={onEditHandler}
	            onDelete={onDeleteHandler}
	          />
	        </div >
	      </Box >
	    </div >
    </Card >
	)
}

export default PeppolNumberComponent