import { Button, Card, Layout, Page, Spinner, Text } from '@shopify/polaris'
import React, { useEffect, useState } from 'react'
import { useSelector } from 'react-redux'
import { useNavigate } from 'react-router-dom'
import useAppDispatch from '../../hooks/useAppDispatch'
import { AddIcon } from '../../icons'
import { fetchCustomers } from '../../redux/features/customers/customersSlice'
import CustomersTable from './CustomersTable'
import DeleteCustomerModal from './DeleteCustomerModal'

const Customers = () => {
	const {customers, fetchingCustomers} = useSelector(state => state.customers)
	const [showDeleteModal, setShowDeleteModal] = useState(false)
	const [deleteCustomerData, setDeleteCustomerData] = useState({})
	const dispatch = useAppDispatch()
	const navigate = useNavigate()
	
	
	useEffect(() => {
		const request = async () => {
			try {
				await dispatch(fetchCustomers())
			} catch (error) {
				console.log(error)
			}
		}
		request()
	}, [])
	
	const editCustomerHandler = (customerValue) => {
		navigate('/customers/create', {state: {customerValue}})
	}
	
	const deleteCustomerHandler = (customer) => {
		setDeleteCustomerData(customer)
		setShowDeleteModal(true)
	}
	
	const hideDeleteModal = async () => {
		setShowDeleteModal(false)
		setDeleteCustomerData({})
		await dispatch(fetchCustomers())
	}
	
	return (
		<Page
			separator
			fullWidth
			title={
				<Text
					variant='heading2xl'
					as='span'
				>
          Customers
        </Text >
			}
			subtitle={
				<Text
					variant='bodyLg'
					as='span'
					color='subdued'
				>
        On this page, you will find all your customers.
        </Text >
			}
			primaryAction={
				<div
					style={{
						position: "absolute",
						right: 0,
						top: "50%",
						transform: "translateY(-50%)",
					}}
				>
            <div style={{display: 'flex', alignItems: 'center'}}>
              <div style={{marginRight: 20}}>
                <Button
	                variant={'primary'}
	                icon={AddIcon}
	                onClick={() => navigate("/customers/create")}
                >
                Add Customer
              </Button >
              </div >
	             <Button
		             secondary
		             disabled
		             // onClick={() => this.props.navigate("/vat-payment")}
	             >
                Import Customer
              </Button >
            </div >
          </div >
			}
		>
			<Layout >
	      <Layout.Section >
		      <Card >
              {fetchingCustomers ? (
	              <div >
                  <Spinner />
                </div >
              ) : (
	              <CustomersTable
		              customers={customers}
		              editCustomerHandler={editCustomerHandler}
		              deleteCustomerHandler={deleteCustomerHandler}
	              />
              )}
            </Card >
	      </Layout.Section >
			</Layout >
			
			{
				showDeleteModal
				&&
				<DeleteCustomerModal
					data={deleteCustomerData}
					onHide={hideDeleteModal}
					show={showDeleteModal}
				/>
			}
		</Page >
	)
}

export default Customers