import { Button, Select } from '@shopify/polaris'
import React, { useEffect, useState } from 'react'
import { useSelector } from 'react-redux'
import SearchingCountry from '../../../components/SearchingCountry/SearchingCountry'
import useAppDispatch from '../../../hooks/useAppDispatch'
import { fetchAddLanguageForPDF, fetchEditLanguageForPDF } from '../../../redux/features/look-up/lookUpSlice'

const AddLanguageForPDFBlock = ({countries, languages, editData, onHide, reUpdateData}) => {
	const {fetchingAddLanguageForPDF, fetchingEditLanguageForPDF} = useSelector(state => state.lookUp)
	const [selectedCountry, setSelectedCountry] = useState(null)
	const [selectedLanguage, setSelectedLanguage] = useState('');
	const dispatch = useAppDispatch()

	const countriesOptions = countries.map((country) => ({
		label: country.name,
		value: country.code,
	}));
	
	const languageOptions = languages.map(lang => ({
		label: lang.name,
		value: lang.code
	}));
	
	useEffect(() => {
		if (editData?.id) {
			setSelectedCountry(editData.country?.code)
			setSelectedLanguage(editData.language?.code || '')
		}
	}, [editData])
	
	const clearData = () => {
		setSelectedCountry(null)
		setSelectedLanguage('')
	}
	
	const submitHandler = async () => {
		const requestData = {
			country_id: selectedCountry,
			language: selectedLanguage
		}
		
		try {
			if (editData?.id) {
				await dispatch(fetchEditLanguageForPDF({...requestData, id: editData?.id}))
			} else {
				await dispatch(fetchAddLanguageForPDF(requestData))
			}
			await reUpdateData()
			clearData()
			onHide()
		} catch (e) {
			console.error(e)
		}
	}
	
	return (
		<>
      <div style={{marginTop: 10, padding: '10px 20px'}}>
        <div >
          <SearchingCountry
	          id='country_id'
	          title='Country'
	          placeholder='Enter your Country'
	          onChange={setSelectedCountry}
	          changeCountry={countriesOptions.find(item => item.value === selectedCountry)?.label || ''}
	          dataCountriesList={countriesOptions}
	          isOpen={true}
          />
        </div >
	      
	      <div style={{marginTop: 10}}>
				  <Select
					  label='Language for PDF'
					  options={languageOptions}
					  onChange={setSelectedLanguage}
					  value={selectedLanguage}
					  placeholder='Select a Language for PDF'
				  />
				</div >
      </div >

      <div style={{marginTop: 10, display: 'flex', alignItems: 'center', paddingLeft: 20}}>
        <Button
	        onClick={submitHandler}
	        size='micro'
	        variant='primary'
	        loading={fetchingAddLanguageForPDF || fetchingEditLanguageForPDF}
	        disabled={!selectedCountry}
        >
          {editData?.id ? 'Edit Language For PDF' : 'Add Language For PDF'}
        </Button >
        <div style={{marginLeft: 10}}>
          <Button
	          variant='secondary'
	          size='micro'
	          onClick={onHide}
          >
            Cancel
          </Button >
        </div >
      </div >
    </>
	)
}

export default AddLanguageForPDFBlock
