import Avatar from 'img/avatar.svg'
import { ReactComponent as LogoLovat } from 'img/logo.svg'
import { ReactComponent as ArrowCircleRight } from 'img/topbar/arrow-circle-right.svg'
import { ReactComponent as Calculator } from 'img/topbar/calculator.svg'
import { ReactComponent as Grafic } from 'img/topbar/grafic.svg'
import { ReactComponent as NineDots } from 'img/topbar/nine-dots.svg'
import React, { useMemo, useState } from 'react'

const TopMenuSelectedProjects = () => {
	const [showMenuChangeProjects, setShowMenuChangeProjects] = useState(false)
	const toggleMenuChangeProjects = () => setShowMenuChangeProjects(!showMenuChangeProjects)
	
	const currentSubdomain = useMemo(() => {
		const host = window.location.hostname;
		console.log('host', host)
		if (host.includes('invoice')) return 'invoice';
		if (host.includes('localhost')) return 'invoice';
		// if (host.includes('localhost')) return 'main';
		return 'main';
	}, []);
	console.log('currentSubdomain', currentSubdomain)
	return (
		<div className={`topbar-wrapper_block ${showMenuChangeProjects ? 'active-dots_icon' : ''}`}>
          <NineDots
	          className={`topbar-wrapper_block-dot`}
	          onClick={toggleMenuChangeProjects}
          />
          <img
	          src={Avatar}
	          alt='Avatar'
	          className='topbar-wrapper_block-avatar'
          />
			{
				showMenuChangeProjects
				&&
				<div className='topbar-wrapper_block-menu'>
				      <div className='topbar-wrapper_block-menu_content'>
					      <h3 className='topbar-wrapper_block-menu_title'>Other Lovat Apps</h3 >
					      <ul className='topbar-wrapper_block-menu_body'>
						      {/*<li className='topbar-wrapper_block-menu_body-item'>*/}
							    {/*  <a*/}
								  {/*    href='/'*/}
								  {/*    className='topbar-wrapper_block-menu_body-item_link'*/}
							    {/*  >*/}
								  {/*    <Calculator className='topbar-wrapper_block-menu_body-item_link-icon' />*/}
								  {/*    Lovat App TAX*/}
								  {/*    <ArrowCircleRight className='topbar-wrapper_block-menu_body-item_link-icon_arrow' />*/}
							    {/*  </a >*/}
									{/*</li >*/}
						      {
							      currentSubdomain === 'invoice'
							      &&
							      <li className='topbar-wrapper_block-menu_body-item'>
							      <a
								      href='https://dev.vatcompliance.co'
								      // href='/einvoice/documents'
								      className='topbar-wrapper_block-menu_body-item_link'
							      >
								      <Grafic className='topbar-wrapper_block-menu_body-item_link-icon' />
							        L tax
								      <ArrowCircleRight className='topbar-wrapper_block-menu_body-item_link-icon_arrow' />
							      </a >
									</li >
						      }
						      {
							      currentSubdomain === 'main'
							      &&
							      <li className='topbar-wrapper_block-menu_body-item'>
							      <a
								      href='https://invoice.dev.vatcompliance.co'
								      // href='/einvoice/documents'
								      className='topbar-wrapper_block-menu_body-item_link'
							      >
								      <Grafic className='topbar-wrapper_block-menu_body-item_link-icon' />
							        L invoice
								      <ArrowCircleRight className='topbar-wrapper_block-menu_body-item_link-icon_arrow' />
							      </a >
									</li >
						      }
						     
								</ul >
							</div >
				      <div className='topbar-wrapper_block-menu_logo'>
                <LogoLovat />
              </div >
			      </div >
			}
        </div >
	)
}

export default TopMenuSelectedProjects