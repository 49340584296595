import { Button, DataTable, Spinner, Text, Tooltip } from '@shopify/polaris'
import React from 'react'
import NoDataMessage from '../../../components/NoDataMessage'
import deleteIMG from '../../../img/delete.svg'
import editIMG from '../../../img/edit.svg'

const TableLanguageForPdf = ({dataTable, loadingTable, onEdit, onDelete}) => {
	const columns = [
		{key: '#', label: '#'},
		{key: 'country', label: 'Country'},
		{key: 'Language for PDFs', label: 'Language for PDFs'},
		{key: 'actions', label: ''},
	];
	
	const rows = dataTable?.map((item, index) => [
		index + 1,
		item?.country?.name,
		item?.language?.name,
		<div style={{display: 'flex', gap: '8px', justifyContent: 'flex-end'}}>
			<div style={{marginRight: 20}}>
				<Tooltip content='Edit'>
        <Button
	        variant='plain'
	        onClick={() => onEdit(item)}
        >
          <img
	          src={editIMG}
	          alt='Edit'
          />
        </Button >
      </Tooltip >
			</div >
      <Tooltip content='Delete'>
        <Button
	        variant='plain'
	        onClick={() => onDelete(item)}
        >
          <img
	          src={deleteIMG}
	          alt='Delete'
          />
        </Button >
      </Tooltip >
    </div >,
	]) || [];
	
	return (
		<div style={{width: '100%'}}>
      {loadingTable ? (
	      <div style={{display: 'flex', justifyContent: 'center', padding: '20px'}}>
          <Spinner />
        </div >
      ) : rows.length > 0 ? (
	      <DataTable
		      columnContentTypes={['text', 'text', 'text', 'text']}
		      headings={columns.map(({label}) => (
			      <Text
				      fontWeight='semibold'
				      variant='bodyMd'
			      >
              {label}
            </Text >
		      ))}
		      rows={rows}
	      />
      ) : (
	      <NoDataMessage
		      description='No data available'
		      buttonText='Refresh'
	      />
      )}
    </div >
	)
}

export default TableLanguageForPdf