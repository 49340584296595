import { Button, TextField } from '@shopify/polaris'
import React, { useEffect, useState } from 'react'
import { useSelector } from 'react-redux'
import SearchingCountry from '../../../components/SearchingCountry/SearchingCountry'
import useAppDispatch from '../../../hooks/useAppDispatch'
import {
	fetchAddPeppolNumber,
	fetchEditPeppol
} from '../../../redux/features/look-up/lookUpSlice'

const AddPeppolBlock = ({countries, editData, onHide, reUpdateData}) => {
	const {fetchingAddPeppolNumber, fetchingEditPeppol} = useSelector(state => state.lookUp)
	const [selectedCountry, setSelectedCountry] = useState(null)
	const [peppolNumberValue, setPeppolNumberValue] = useState('')
	const dispatch = useAppDispatch()
	
	const countriesOptions = countries.map((country) => ({
		label: country.name,
		value: country.code,
	}));
	
	useEffect(() => {
		if (editData?.id) {
			setPeppolNumberValue(editData.peppol_number)
			setSelectedCountry(editData.country_id)
		}
	}, [editData])
	
	const clearData = () => {
		setSelectedCountry(null)
		setPeppolNumberValue('')
	}
	
	const submitHandler = async () => {
		const requestData = {
			id: editData?.id,
			status: editData?.activate,
			country_id: selectedCountry,
			peppol_number: peppolNumberValue
		}
		
		try {
			if (editData?.id) {
				await dispatch((fetchEditPeppol({...requestData, id: editData?.id})))
			} else {
				await dispatch(fetchAddPeppolNumber(requestData))
			}
			await reUpdateData()
			clearData()
			onHide()
		} catch (e) {
			console.error(e)
		}
	}
	
	return (
		<>
      <div style={{marginTop: 10, padding: '10px 20px'}}>
        <div >
          <SearchingCountry
	          id='country_id'
	          title='Country'
	          placeholder='Enter your Country'
	          onChange={setSelectedCountry}
	          changeCountry={countriesOptions.find(item => item.value === selectedCountry)?.label || ''}
	          dataCountriesList={countriesOptions}
	          isOpen={true}
          />
        </div >

        <div style={{marginTop: 10}}>
          <TextField
	          label='Peppol Number'
	          value={peppolNumberValue}
	          placeholder='Enter Peppol Number'
	          onChange={setPeppolNumberValue}
	          autoComplete='off'
          />
        </div >
      </div >

      <div style={{marginTop: 10, display: 'flex', alignItems: 'center', paddingLeft: 20}}>
        <Button
	        onClick={submitHandler}
	        size='micro'
	        variant='primary'
	        loading={fetchingAddPeppolNumber || fetchingEditPeppol}
	        disabled={!peppolNumberValue || !selectedCountry}
        >
          {editData?.id ? 'Edit Peppol Number' : 'Add Peppol Number'}
        </Button >
        <div style={{marginLeft: 10}}>
          <Button
	          variant='secondary'
	          size='micro'
	          onClick={onHide}
          >
            Cancel
          </Button >
        </div >
      </div >
    </>
	)
}

export default AddPeppolBlock
