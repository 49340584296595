import { Button, DataTable, Text, Tooltip } from "@shopify/polaris"
import _ from 'lodash'
import React from "react"
import { useTranslation } from "react-i18next"
import NoDataMessage from "../../components/NoDataMessage"
import deleteIMG from "../../img/delete.svg"
import editIMG from "../../img/edit.svg"
import formatRow from '../../utils/formatRow'
import paginate from '../../utils/paginate'

const queryReq= {
	offset: 10,
	limit: 20,
	search: "customer",
	sort: "name",
}

const CustomersTable = ({customers, editCustomerHandler, deleteCustomerHandler}) => {
	const {t} = useTranslation()
	const rows = customers
	const pagination = _.pick(queryReq, ["offset", "limit"])
	
	const columns = [
		{
			property: "name",
			header: {
				label: 'Business Name',
			},
		},
		{
			property: 'contact_person',
			header: {
				label: 'Contact Person',
			},
		},
		{
			property:'email',
			header: {
				label: 'Email',
			},
		},
		{
			property: "peppol_id",
			header: {
				label: 'Peppol ID',
			},
			cell: {
				formatters: [
					(value, { rowData }) => {
						return `${rowData.peppol_id}` || '-'
					}
				],
			},
		},
		{
			property: "country",
			header: {
				label: t("vatReturns.country"),
			},
			cell: {
				formatters: [
					(value, { rowData }) =>
						`${rowData.country?.name}`
				],
			},
		},
		{
			property:'address_line_1',
			header: {
				label: 'Address',
			},
			cell: {
				formatters: [
					(value, { rowData }) => {
						return rowData.address_line_2
							? `${rowData.address_line_1} / ${rowData.address_line_2}`
							: rowData.address_line_1
					}
				],
			},
		},
		{
			property:'Actions',
			header: {
				label: '',
			},
			cell: {
				formatters: [
					(value, {rowData}) => {
					return (
						<div
							style={{
								display: "flex",
								alignItems: "center",
								gap: "2",
							}}
						>
			      <Tooltip content={t("vatReturns.edit")}>
			        <Button
				        variant='plain'
				        icon={<img
					        src={editIMG}
					        alt='edit'
				        />}
				        onClick={() => editCustomerHandler(rowData)}
			        />
			      </Tooltip >
			      <Tooltip content={t("vatReturns.delete")}>
			        <Button
				        variant='plain'
				        icon={<img
					        src={deleteIMG}
					        alt='delete'
				        />}
				        onClick={() => deleteCustomerHandler(rowData)}
			        />
			      </Tooltip >
			    </div >
					)
					}
				]
			}
		},
	]
	
	const sortedRows = formatRow(rows, columns)
	// const paginatedRows = paginate(pagination)(sortedRows)
	
	return (
		<>
      <DataTable
	      columnContentTypes={Array(columns.length).fill("text")}
	      headings={columns?.map(({ header }) => (
		      <Text fontWeight='semibold' variant={'bodyMd'}>{header.label}</Text>
	      ))}
	      rows={sortedRows}
      />
			{customers.length === 0 && (
				<NoDataMessage
					description={t("vatReturns.noData")}
					buttonText={t("vatReturns.addCustomer")}
					onClick={() => console.log("Добавить")}
				/>
			)}
    </>
	)
}

export default CustomersTable
