import {
  Page,
  Layout,
  Button,
  Card,
  Select,
  FormLayout,
  ButtonGroup,
  TextField,
  Text,
  DataTable,
  Checkbox,
  List,
  Tooltip,
  RadioButton, Box,
} from "@shopify/polaris";
import PageHelmet from "components/PageHelmet/PageHelmet";
import { t } from "i18next";
import _, { uniqueId } from "lodash";
import * as PropTypes from 'prop-types'
import React, { Component, Fragment } from "react";
import { withTranslation } from "react-i18next";
import { connect } from "react-redux";
import { Link } from "react-router-dom";
import Modal from "components/Modal";
import SaveBar from "components/SaveBar/SaveBar";
import Stepper from "components/Stepper";
import withRouter from "helpers/withRouter";
import BannerCustom from '../../components/Banners/BannerCustom'
import GoBackPageNavigation from '../../components/GoBackPageNavigation/GoBackPageNavigation'
import SubscriptionModal from '../../components/UpdatedModalWindows/SubscriptionModal'
import ArrowLeftBack from '../../img/arrow-left-for-button-back.svg'
import {
  createEprDeclaration,
  createEprFurnitureEcoCode,
  fetchEPROperators,
  fetchEprCountries,
  fetchEprFurnitureCategories,
  fetchEprFurnitureChoice,
  fetchEprFurnitureFeatures,
  fetchEprFurnitureMaterials,
  fetchEprFurnitureProducts,
  fetchEprStatus,
  fetchFiltersEPR,
  updateEpr, setResetData, setResetEprDeclaration,
} from "../../redux/features/declarations/declarationsSlice";
import { formatMoney } from "utils/numbers";
import formatRow from "utils/formatRow";
import { resetSystemMessage } from '../../redux/features/messenger/messengerSlice'
import FurniturePreview from "./FurniturePreview";

import step1IMG from "img/step1.svg";
import parrotIMG from "img/parrot.svg";
import deleteIMG from "img/delete.svg";
import editIMG from "img/settings.svg";
import PackagingPreview from "./PackagingPreview";
import { getCurrencySymbol } from "utils/currency";

import "./index.scss";
import classNames from "classnames";
import { getPackagingSchemaForCountry } from "./packagingSchemas";
import { buildPeriods } from "utils/periods";
import NoDataMessage from "components/NoDataMessage/NoDataMessage";
import { formatDate } from "utils/dates";
import StepLogicHandler from './Subcategories/StepLogicHandler/StepLogicHandler'
import TextilePreview from "./TextilePreview";
import PackagingPreviewSimplified from "./PackagingPreviewSimplified";
import TextilePreviewSimplified from "./TextilePreviewSimplified";
import { createCustomDispatch } from "helpers/customDispatch";
import ArrowRight from '../../img/arrow-right-white.svg'

function Typography(props) {
  return null
}

Typography.propTypes = {
  variant: PropTypes.string,
  fontWeight: PropTypes.string,
  children: PropTypes.node
}

class AddEPRReporting extends Component {
  constructor(props) {
    super(props);

    const params = this.props.params || {};

    this.state = {
      country: params.countryId || "",
      year: params.year || "",
      period: params.period || "",
      periodType: params.periodType || "",
      reportType: params.reportType || "",
      countries: [],
      years: [],
      reportTypesOptions: [],

      stepIndex: 0,

      furnitureSelectFields: {
        category: "",
        product: "",
        material: "",
        feature: "",
        number: "",
        name: "",
      },

      textileSelectFields: {
        category: "",
        product: "",
        productTypology: "",
        number: "",
      },

      furnitureTableData: [],
      textileTableData: [],
      textileQuestions: [],

      actionDialogs: {
        subscription: { open: false },
        reset: { open: false },
        created: { open: false },
        submitted: { open: false },
        delete: { open: false },
        furnitureItem: { open: false },
        uploadingProgress: { open: false },
        textileItem: { open: false },
      },

      simplified: false,
      revenue: null,
      tax: null,

      isChecked: false,
      createEprDeclarationError: null,

      uploadingWarningConfirm: false,

      product: null,
    };
  }
  componentDidUpdate(prevProps) {
    const { params = {}, eprCategories } = this.props;
    const { reportTypesOptions } = this.state;

    const { countryId, reportType } = params;

    if (
      countryId &&
      !_.isEmpty(eprCategories) &&
      _.isEmpty(reportTypesOptions)
    ) {
      const countryData = eprCategories[countryId] || [];
      const reportData = reportType
        ? countryData.find(({ code }) => code === reportType) || {
            years: [],
          }
        : { years: [] };

      this.setState({
        reportTypesOptions: countryData,
        years: reportData.years.map((year) => ({
          label: year,
          value: year.toString(),
        })),
      });
    }
  }

  async componentDidMount() {
    this.props.fetchEprStatus();

    const countries = await this.props.fetchEprCountries();

    this.props.fetchFiltersEPR().then((res) => {
      this.setState({
        countries: countries
          .filter(({ code }) => Object.keys(res).includes(code))
          .map((country) => ({
            value: country.code,
            label: country.name,
          })),
      });
    });
  }

  getStepContent() {
    const { stepIndex, reportType } = this.state;
    const { eprDeclaration } = this.props;
    const hasSubcategories = !_.isEmpty(eprDeclaration.subcategories_data);
    
    if (hasSubcategories) {
      return this.getSubcategoriesSteps(stepIndex);
    }
    switch (reportType) {
      case 1:
        return this.getStepsForTextile(stepIndex);
      case 2:
        return this.getStepsForFurniture(stepIndex);
      case 7:
        return this.getStepsForPackaging(stepIndex);

      default:
        return this.step1();
    }
  }

  getStepsForFurniture(stepIndex) {
    const { simplified } = this.state;
   
    if (simplified) {
      switch (stepIndex) {
        case 1:
          return this.step2Simplified();
        case 2:
          return this.step3Simplified();

        default:
          return this.step1();
      }
    } else {
      switch (stepIndex) {
        case 1:
          return this.step2();
        case 2:
          return this.step3();

        default:
          return this.step1();
      }
    }
  }

  getSubcategoriesSteps = (stepIndex) => {
    const subcategoryProps = {
      toNextStep: this.handleNext,
      toPrevStep: this.handlePrev,
    };
    const { eprDeclaration } = this.props;
    return (
      <div className='epr-declaration_create'>
         <StepLogicHandler
           stepIndex={stepIndex}
           eprDeclaration={eprDeclaration}
           handleNext={this.handleNext}
           handlePrev={this.handlePrev}
           subcategoryProps={subcategoryProps}
           handleActionDialogsOpen={this.handleActionDialogsOpen}
           renderSummaryStepBar={this.renderSummaryStepBar}
         />
      </div>
    )
  };

  getStepsForPackaging(stepIndex) {
    const { simplified } = this.state;

    if (simplified) {
      switch (stepIndex) {
        case 1:
          return this.step2SimplifiedPackaging();
        case 2:
          return this.step3SimplifiedPackaging();

        default:
          return this.step1();
      }
    } else {
      switch (stepIndex) {
        case 1:
          return this.step2ForPackaging();
        case 2:
          return this.step3ForPackaging();

        default:
          return this.step1();
      }
    }
  }

  getStepsForTextile(stepIndex) {
    const { simplified } = this.state;

    if (simplified) {
      switch (stepIndex) {
        case 1:
          return this.step2SimplifiedTextile();
        case 2:
          return this.step3SimplifiedTextile();

        default:
          return this.step1();
      }
    } else {
      switch (stepIndex) {
        case 1:
          return this.step2ForTextile();
        case 2:
          return this.step3ForTextile();

        default:
          return this.step1();
      }
    }
  }

  getDataForTypeOfReport = (repType) => {
    const { furnitureTableData , packagingData } = this.state;
    
    switch (repType) {
      case 1:
        return this.getTextileTableData();
      case 2:
        return furnitureTableData;
      case 7:
        return packagingData;
      default:
        return null;
    }
  };

  getCurrentReportType = () => {
    const { reportTypesOptions, reportType } = this.state;

    return reportTypesOptions.find(({ code }) => code === reportType);
  };

  getTextileQuestions = (categories_questions) => {
    const { questions } = categories_questions;
    
    return questions.map((q) => ({
      id: q.id,
      value: false,
    }));
  };

  fillReturn(finalType) {
    const { eprDeclaration, eprSubcategoriesResult} = this.props;
    const {
      year,
      simplified,
      revenue,
      stepIndex,
      tax,
      period,
      number_of_csu,
      product_description,
      clothing,
      footwear,
      household_linen,
      textileQuestions,
      reportType: type_of_report,
      country: customer_country_id,
      periodType: period_type,
    } = this.state;
    const system_operator_id = this.getCurrentReportType().system_operator_id;
    const data = this.getDataForTypeOfReport(type_of_report);
    const hasSubcategories = !_.isEmpty(eprDeclaration.subcategories_data);
    
    const declarationData = {
      data,
      simplified,
      customer_country_id,
      period_type,
      type_of_report,
      system_operator_id,
      questions: textileQuestions,
      year: Number(year),
      period: period.at(0),
    };

    if (finalType) {
      // console.log('START IF')
      declarationData[finalType] = true;
      const tehQuantityObj = eprSubcategoriesResult.quantityArr.map(item => ({
        quantity: item.quantity,
        subcategory_1: item.category,
        subcategory_2: item.subCategory,
        subcategory_3: item.subSubCategory,
        subcategory_4: item.subSubSubCategory,
        recyclateOfPrimary: item.recyclateOfPrimary
      }))
      const tehQuantityObj5 = eprSubcategoriesResult.selectedCategory5.map(item => ({
        quantity: item[5],
        subcategory_1: item[0],
        subcategory_2: item[1],
        subcategory_3: item[2],
        subcategory_4: item[3],
        recyclateOfPrimary: item[4]
      }))
     
      const finallyArrElements = eprSubcategoriesResult.quantityArr.length > 0 ? tehQuantityObj :  tehQuantityObj5
      
      if (hasSubcategories) {
        declarationData.subcategories_data = finallyArrElements
      }

      if (simplified) {
        if (type_of_report === 1) {
          declarationData.clothing = clothing;
          declarationData.footwear = footwear;
          declarationData.household_linen = household_linen;
        } else if (type_of_report === 2) {
          declarationData.revenue = Number(revenue);
          declarationData.fee = (revenue * tax) / 100;
          declarationData.tax = tax;
        } else if (type_of_report === 7) {
          declarationData.number_of_csu = number_of_csu;
          declarationData.product_description = product_description;
        }
      }
      // console.log('finalType 1', finalType)
      this.props
        .createEprDeclaration(declarationData)
        .then((result) => {
          // console.log('result start')
          if (result) {
            if (finalType === "report") {
              return this.downloadFile(result);
            } else {
              this.handleActionDialogsOpen("created", {
                finalType,
                addingResult: result ?? true,
                addingResultMessage: "",
              });
            }
          } else {
            this.handleActionDialogsOpen("subscription", {
              addingResultMessage: result.message,
            });
          }
          // console.log('result finish')
        })
        .catch((result) => {
          // console.log('catch start')
            this.setState({createEprDeclarationError: result})
          // console.log('catch finish')
          }
        );
      // console.log('FINISH IF')
    } else {
      this.props
        .createEprDeclaration(declarationData)
        .then((res) => {
          // console.log('res', res)
          if (res.message) {
            this.handleActionDialogsOpen("subscription", {
              addingResultMessage: res.message,
            });
          } else {
            const newState = {
              stepIndex: stepIndex + 1,
            };

            if (simplified) {
              if (type_of_report === 1) {
                newState.clothing = res.clothing;
                newState.footwear = res.footwear;
                newState.household_linen = res.household_linen;
              } else if (type_of_report === 2) {
                newState.tax = res.tax;
              } else if (type_of_report === 7) {
                newState.number_of_csu = res.number_of_csu;
                newState.product_description = res.product_description;
              }
            } else {
              
              if (type_of_report === 1) {
                newState.product = res.product;
                newState.textileQuestions = this.getTextileQuestions(
                  res.product.categories_questions
                );
              } else if (type_of_report === 2) {
                newState.furnitureTableData = res.data;
              } else if (type_of_report === 7) {
                newState.packagingData = res.data;
              }
            }

            this.setState({
              ...newState,
            });
          }
        })
        .finally(() => {
          // console.log('stepIndex', stepIndex)
          if (stepIndex === 0) {
            this.props.fetchEprFurnitureCategories(customer_country_id);
          }
        })
        .catch((result) =>
          this.setState({ createEprDeclarationError: result })
        );
    }
  }

  downloadFile(link) {
    const from = link.indexOf("/") + 1;
    const fileName = link.substring(from);
    const a = document.createElement("a");
    a.setAttribute("href", link);
    a.setAttribute("download", fileName);
    a.style.display = "none";

    document.body.appendChild(a);
    a.click();

    window.URL.revokeObjectURL(link);
    document.body.removeChild(a);
  }

  handleActionDialogsOpen = (name, data = {}) => {
    const { actionDialogs } = this.state;
    actionDialogs[name].open = true;
    actionDialogs.cellData = data;
    this.setState({ actionDialogs });
  };

  handleActionDialogsClose(name) {
    const { actionDialogs } = this.state;
    actionDialogs[name].open = false;
    actionDialogs.cellData = {};
    this.setState({ actionDialogs });
     if (name === 'created' || name === 'reset' ) {
       this.props.setResetData({
         selectedCategory1: [],
         selectedCategory2: [],
         selectedCategory3: [],
         selectedCategory4: [],
         selectedCategory5: [],
         quantityArr: [],
       })
       this.props.setResetEprDeclaration({})
       // this.props.navigate("/epr-reporting/add")
     }
  }

  handleNext = async () => {
    const { stepIndex } = this.state;
    // await this.props.fetchEprStatus()

    this.setState({
      stepIndex: stepIndex + 1,
    });
  };

  handlePrev = () => {
    const { stepIndex } = this.state;
    if (stepIndex > 1) {
      this.setState({
        stepIndex: stepIndex - 1,
      });
    }
  };

  handlePrev3Step = async () => {
    this.handlePrev();

    await this.props.fetchEprStatus();
  };

  showUploadingWarningModal = () => {
    const isUploading = this.getUploadingProcessStatus();

    if (isUploading) {
      this.handleActionDialogsOpen("uploadingProgress");
    } else {
      this.handleNext();
    }
  };

  getStepsForTypeOfReport = () => {
    const { reportType, simplified } = this.state;
    const { eprDeclaration } = this.props;
    const hasSubcategories = !_.isEmpty(eprDeclaration.subcategories_data);
    
    const keys = eprDeclaration?.subcategories_data && Object.keys(eprDeclaration?.subcategories_data);
    
    let res = [];
    
    for (let i = 0; i < keys?.length; i++) {
      if (keys[i] !== "units") {
        res.push({ st: i + 2, name: `Subcategory ${i + 1}` });
      }
    }
   
    if (hasSubcategories) {
      return [
        { st: 1, name: t("eprReporting.returnParameters") },
        ...res,
        { st: res.length + 2, name: "Quantity" },
        { st: res.length + 3, name: t("eprReporting.summary") }
      ];
    }
  
    switch (reportType) {
      case 1:
        return [
          { st: 1, name: t("eprReporting.returnParameters") },
          {
            st: 2,
            name: t("eprReporting.editTextileData"),
          },
          { st: 3, name: t("eprReporting.summary") },
        ];
      case 2:
        return [
          { st: 1, name: t("eprReporting.returnParameters") },
          {
            st: 2,
            name: simplified
              ? t("eprReporting.revenueExcludingVAT")
              : t("eprReporting.furnitureEcoparticipations"),
          },
          { st: 3, name: t("eprReporting.summary") },
        ];
      case 7:
        return [
          { st: 1, name: t("eprReporting.returnParameters") },
          { st: 2, name: t("eprReporting.editPackagingData") },
          { st: 3, name: t("eprReporting.summary") },
        ];

      default:
        return [];
    }
  };
  
  renderSubscriptionDialog = () => {
    const { actionDialogs } = this.state;
    // const isModalVisible = actionDialogs?.cellData?.addingResultMessage ?? false;
    // const isModalVisible = (actionDialogs?.cellData?.addingResultMessage?.trim() ? true : false);
    // console.log('actionDialogs?.cellData', actionDialogs?.cellData)
    // console.log('actionDialogs', actionDialogs)
    return (
      // isModalVisible && (
      <SubscriptionModal
        title={actionDialogs.cellData?.addingResultMessage}
        visible={actionDialogs.subscription.open}
        onClose={() => this.handleActionDialogsClose("subscription")}
        text={t("createReturns.contUsing")}
      />
      // )
    );
  };

  renderResetDialog() {
    const { actionDialogs, stepIndex } = this.state;
    const { t } = this.props;

    return (
      <Modal
        title={t("createReturns.warning")}
        visible={actionDialogs.reset.open}
        onClose={() => this.handleActionDialogsClose("reset")}
        contentOnCenter
        iconType={"warning"}
        description={
          <>
            <p>{t("createReturns.willLose")}</p>
            <p>{t("createReturns.wantComeBack")}</p>
          </>
        }
        footer={
          <div style={{ display: 'flex', justifyContent: 'center' }}>
            <Button
              onClick={() => {
                this.setState({
                  stepIndex: stepIndex - 1,
                  data: [],
                });
                this.handleActionDialogsClose("reset");
              }}
            >
              {t("createReturns.toTheFirst")}
            </Button>
          </div>
        }
      />
    );
  }

  renderStepsSaveBar = ({ onBackClick, onNextClick, isNextButtonDisabled }) => {
    return (
      <SaveBar>
        <ButtonGroup>
          <Button size={'micro'} onClick={onBackClick}> <img src={ArrowLeftBack} alt='arrow' style={{width: 10, height:10 , marginRight:8}}/>{t("eprReporting.back")}</Button>
          <Button size={'micro'} variant='primary' disabled={isNextButtonDisabled} onClick={onNextClick}>
            {t("orderReg.next")}<img src={ArrowRight} alt='arrow' style={{width: 10, height:10 , marginLeft:8}}/>
          </Button>
        </ButtonGroup>
      </SaveBar>
    );
  };

  renderSummaryStepBar = ({ handleBack } = {}) => {
    const onPrevClick = handleBack ?? this.handlePrev;

    return (
      <SaveBar>
        <ButtonGroup>
          <Button size={'micro'} onClick={onPrevClick}> <img src={ArrowLeftBack} alt='arrow' style={{width: 10, height:10 , marginRight:8}}/>{t("eprReporting.back")}</Button>
          <Button
            variant='primary'
            size={'micro'}
            onClick={() => {
              this.handleActionDialogsOpen("submitted");
            }}
          >
            {t("eprReporting.submit")}
          </Button>
          <Button variant='primary' size={'micro'} onClick={() => this.fillReturn("save")}>
            {t("eprReporting.save")}
          </Button>
          {/*<Button primary size={'micro'} onClick={() => this.fillReturn("report")}>*/}
          {/*  {t("eprReporting.downloadReport")}*/}
          {/*</Button>*/}
        </ButtonGroup>
      </SaveBar>
    );
  };

  step2Simplified() {
    const { revenue } = this.state;

    return (
      <>
        <Card>
          <FormLayout.Group>
            <TextField
              label='Enter your revenue excluding vat:'
              type='number'
              value={revenue}
              onChange={(value) => this.setState({ revenue: value })}
            />
            <br />
          </FormLayout.Group>
          <br />
          {this.renderStepsSaveBar({
            isNextButtonDisabled: !revenue,
            onBackClick: () => this.handleActionDialogsOpen("reset", {}),
            onNextClick: () => this.handleNext(),
          })}
        </Card>
        
      </>
    );
  }

  step3Simplified() {
    const { revenue, tax } = this.state;
    const fee = (revenue * tax) / 100;

    const columns = [
      {
        property: "revenue",
        header: {
          label: t("eprReporting.revenue"),
        },
        cell: {
          formatters: [
            (value) => formatMoney(value) + getCurrencySymbol("eur"),
          ],
        },
      },
      {
        property: "tax",
        header: {
          label: t("eprReporting.tax"),
        },
        cell: {
          formatters: [(value) => `${value}%`],
        },
      },
      {
        property: "fee",
        header: {
          label: t("eprReporting.fee"),
        },
        cell: {
          formatters: [
            (value) => formatMoney(value) + getCurrencySymbol("eur"),
          ],
        },
      },
    ];

    const sortedRows = formatRow([{ revenue, tax, fee }], columns);

    return (
      <>
        <Card>
          <DataTable
            columnContentTypes={["text", "text", "numeric"]}
            headings={columns.map(({ header }) => (
              <Text fontWeight='semibold' variant={'bodyMd'}>{header.label}</Text>
            ))}
            rows={sortedRows}
            hideScrollIndicator
          />
        </Card>
        {this.renderSummaryStepBar()};
      </>
    );
  }

  step2SimplifiedPackaging() {
    const { number_of_csu, product_description } = this.state;

    return (
      <>
        <Card sectioned>
          <FormLayout>
            <FormLayout.Group>
              <TextField
                label='Enter your product description:'
                type='text'
                value={product_description}
                onChange={(value) =>
                  this.setState({ product_description: value })
                }
              />
              <TextField
                label='Enter your number of CSU:'
                type='number'
                value={number_of_csu}
                onChange={(value) => this.setState({ number_of_csu: value })}
              />
            </FormLayout.Group>
          </FormLayout>
        </Card>
        {this.renderStepsSaveBar({
          isNextButtonDisabled: !(number_of_csu && product_description),
          onBackClick: () => this.handleActionDialogsOpen("reset", {}),
          onNextClick: () => this.handleNext(),
        })}
      </>
    );
  }

  step2SimplifiedTextile() {
    const { household_linen, clothing, footwear } = this.state;
    const isNextButtonDisabled = [household_linen, clothing, footwear].every(
      (item) => !Boolean(item)
    );

    return (
      <>
        <Card sectioned>
          <FormLayout>
            <FormLayout.Group>
              <TextField
                label='Clothing'
                type='number'
                value={clothing}
                onChange={(value) => this.setState({ clothing: value })}
              />
              <TextField
                label='Household linen'
                type='text'
                value={household_linen}
                onChange={(value) => this.setState({ household_linen: value })}
              />
            </FormLayout.Group>
            <FormLayout.Group>
              <TextField
                label='Footwear'
                type='number'
                value={footwear}
                onChange={(value) => this.setState({ footwear: value })}
              />
              <br />
            </FormLayout.Group>
          </FormLayout>
        </Card>
        {this.renderStepsSaveBar({
          isNextButtonDisabled,
          onBackClick: () => this.handleActionDialogsOpen("reset", {}),
          onNextClick: () => this.handleNext(),
        })}
      </>
    );
  }

  step3SimplifiedPackaging() {
    const { number_of_csu, product_description } = this.state;
    const data = { number_of_csu, product_description };

    return (
      <>
        <PackagingPreviewSimplified data={data} />
        {this.renderSummaryStepBar()}
      </>
    );
  }

  step3SimplifiedTextile() {
    const { household_linen, clothing, footwear } = this.state;
    const data = { household_linen, clothing, footwear };

    return (
      <>
        <TextilePreviewSimplified data={data} />
        {this.renderSummaryStepBar()}
      </>
    );
  }

  getReportTypeOptions = () => {
    const { reportTypesOptions } = this.state;

    const mapDeclarationType = ({ code, name, ...type }) => ({
      value: code.toString(),
      label: name,
      ...type,
    });

    const defaultOption = { value: "", label: "" };

    const options = reportTypesOptions.map(mapDeclarationType);

    return [defaultOption, ...options];
  };

  getIsSimplifiedDeclarationAllowed = (reportType, country) => {
    const allowedReportTypes = [1, 2, 7];
    const excludedCountries = ["DEU", "POL"];
    return (
      allowedReportTypes.includes(reportType) &&
      !excludedCountries.includes(country)
    );
  };

  getPeriodOptionsForReportType = (reportType) => {
    if (reportType === 1) {
      return buildPeriods({ year: true });
    }
    return buildPeriods({ quarters: true, year: true });
  };

  step1() {
    const { t, eprCategories } = this.props;
    const {
      countries: availableCountries,
      years,
      country,
      reportType,
      period,
      year,
      simplified,
    } = this.state;

    const isSimplifiedAllowed = this.getIsSimplifiedDeclarationAllowed(
      reportType,
      country
    );

    const isNextButtonDisabled = [year, reportType, period, country].some(
      (v) => !Boolean(v)
    );

    const countries = [
      {
        value: "",
        label: "",
      },
      ...availableCountries,
    ];

    const yearsOptions = [{ value: "", label: "" }, ...years];

    const periodOptions = this.getPeriodOptionsForReportType(reportType);
    
    const onCountryChange = (value) => {
      this.setState({
        country: value,
        reportTypesOptions: eprCategories[value],
        reportType: "",
        year: "",
        years: [],
      });
    };
    
    const onReportTypeChange = (value) => {
      const { eprCategories } = this.props;
      const { country, period, periodType } = this.state; // добавляем период и периодТип
      
      const selectedCategory = eprCategories[country].find(
        ({ code }) => code.toString() === value
      );
      
      const newState = {
        reportType: Number(value),
        tax: null,
        period: period || null,
        periodType: periodType || null,
        years: [],
        year: "",
      };
      
      if (selectedCategory) {
        const years = selectedCategory.years.map((year) => ({
          label: year.toString(),
          value: year.toString(),
        }));
        
        newState.years = years;
      }
      
      this.setState(newState);
    };
    
    const onYearChange = (value) => {
      this.setState({
        year: value,
      });
    };
    
    const onPeriodChange = (value) => {
      let periodType = "";
      if (value.includes("y")) {
        periodType = "year";
      } else {
        periodType = "quarter";
      }

      this.setState({
        period: value,
        periodType,
      });
    };

    const onDeclarationMethodClick = (simplified) => {
      this.setState({
        simplified,
      });
    };

    const getDeclarationMethodClassName = (active) => {
      return classNames("declarationMethod", {
        active,
      });
    };

    return (
      <Layout>
        <Layout.AnnotatedSection title={t("createReturns.fillReturnParams")}>
          <Card sectioned>
            <FormLayout>
              <FormLayout.Group>
                <Select
                  id='country'
                  name='country'
                  label={t("eprReporting.country")}
                  options={countries}
                  onChange={onCountryChange}
                  value={country}
                />
                <Select
                  id='reportType'
                  name='report_type'
                  label={t("eprReporting.reportType")}
                  options={this.getReportTypeOptions()}
                  onChange={onReportTypeChange}
                  value={reportType ? reportType.toString() : ""}
                />
              </FormLayout.Group>
              <FormLayout.Group>
                <Select
                  id='period'
                  name='period'
                  label={t("eprReporting.period")}
                  options={periodOptions}
                  onChange={onPeriodChange}
                  value={period}
                />
                <Select
                  id='year'
                  name='year'
                  label={t("eprReporting.year")}
                  options={yearsOptions}
                  onChange={onYearChange}
                  value={year}
                />
              </FormLayout.Group>
            </FormLayout>
            <br />
          </Card>
          {(isSimplifiedAllowed && country === 'FRA') && (
            <Card sectioned>
              <Text variant='bodyMd'>
                {t("eprReporting.selectDeclarationMethod")}
              </Text>
              <div className='declarationMethodWrapper'>
                <div
                  className={getDeclarationMethodClassName(!simplified)}
                  onClick={() => onDeclarationMethodClick(false)}
                >
                  <Text variant='bodyLg' fontWeight='semibold'>
                    {t("eprReporting.detailedDeclaration")}
                  </Text>
                  <List type='bullet'>
                    <List.Item>
                      <Text color='subdued'>
                        {t("eprReporting.fourTypesOfReportingParameters")}
                      </Text>
                    </List.Item>
                    <List.Item>
                      <Text color='subdued'>
                        {t("eprReporting.volumesDeclaredPerProductLine")}
                      </Text>
                    </List.Item>
                    <List.Item>
                      <Text color='subdued'>
                        {" "}
                        {t("eprReporting.standardScale")}
                      </Text>
                    </List.Item>
                  </List>
                </div>
                <div
                  className={getDeclarationMethodClassName(simplified)}
                  onClick={() => onDeclarationMethodClick(true)}
                >
                  <Text variant='bodyLg' fontWeight='semibold'>
                    {t("eprReporting.simplifiedDeclaration")}
                  </Text>
                  <List type='bullet'>
                    <List.Item>
                      <Text color='subdued'>
                        {t("eprReporting.twoStepsDeclaration")}
                      </Text>
                    </List.Item>
                    <List.Item>
                      <Text color='subdued'>
                        {t("eprReporting.volumesDeclaredPerProductFamily")}
                      </Text>
                    </List.Item>
                    <List.Item>
                      <Text color='subdued' fontWeight='semibold'>
                        {t("eprReporting.pricePerItemIncreased")}
                      </Text>
                    </List.Item>
                  </List>
                </div>
              </div>
              <br />
            </Card>
          )}
          <SaveBar>
            <ButtonGroup>
              <Button
                variant='primary'
                size={'micro'}
                disabled={isNextButtonDisabled}
                onClick={() => this.fillReturn()}
              >
                {t("orderReg.next")}<img src={ArrowRight} alt='arrow' style={{width: 10, height:10 , marginLeft:8}}/>
              </Button>
            </ButtonGroup>
          </SaveBar>
        </Layout.AnnotatedSection>
      </Layout>
    );
  }

  step2() {
    const { t } = this.props;
    const { furnitureTableData } = this.state;
    
    const handleAddItem = () =>
      this.handleActionDialogsOpen("furnitureItem", { type: "create" });

    const handleEditItem = (data) => {
      this.handleActionDialogsOpen("furnitureItem", {
        type: "update",
      });
      
      this.props.fetchEprFurnitureChoice(data.code);
      
      this.setState({
        furnitureSelectFields: data,
      });
    };

    const columns = [
      {
        property: "code",
        header: {
          label: t("eprReporting.code"),
        },
      },
      {
        property: "name",
        header: {
          label: t("eprReporting.name"),
        },
      },
      {
        property: "Tarif_HT",
        header: {
          label: t("eprReporting.tarifHT"),
        },
      },
      {
        property: "Tarif_TTC",
        header: {
          label: t("eprReporting.tarifTTC"),
        },
      },
      {
        property: "Code_Ecopart",
        header: {
          label: t("eprReporting.codeEcopart"),
        },
      },
      {
        property: "number",
        header: {
          label: t("eprReporting.number"),
        },
      },
      {
        property: "summ",
        header: {
          label: t("eprReporting.summ"),
        },
        cell: {
          formatters: [(value) => formatMoney(value)],
        },
      },
      {
        property: "",
        header: {
          label: "",
        },
        cell: {
          formatters: [
            (value, { rowData }) => (
              <Box
                display="flex"
                alignItems="center"
                gap="2"
              >
                <Tooltip content='Edit'>
                  <Button variant={'plain'} onClick={() => handleEditItem(rowData)}>
                    <img src={editIMG} alt='icon edit' />
                  </Button>
                </Tooltip>

                <Tooltip content='Delete'>
                  <Button
                    variant={'plain'}
                    onClick={() =>
                      this.handleActionDialogsOpen("delete", {
                        ...rowData,
                        dataKey: "furnitureTableData",
                        itemKey: "code",
                      })
                    }
                  >
                    <img src={deleteIMG} alt='icon delete' />
                  </Button>
                </Tooltip>
              </Box>
            ),
          ],
        },
      },
    ];
 
    const sortedRows = formatRow(furnitureTableData, columns)
    
    return (
      <div>
        {this.renderBannerWithUploadTransactions()}

        <Card>
          <DataTable
            columnContentTypes={[
              "text",
              "text",
              "text",
              "text",
              "text",
              "text",
              "text",
            ]}
            headings={columns.map(({ header }) => (
              <Text fontWeight='semibold' variant={'bodyMd'}>{header.label}</Text>
            ))}
            rows={sortedRows}
            hideScrollIndicator
            truncate
          />
          {_.isEmpty(furnitureTableData) && (
            <NoDataMessage
              title='There are no items'
              description={"To see items, you need to add them first"}
              buttonText={"Add items"}
              onClick={handleAddItem}
            />
          )}
        </Card>
        <br />
        <Button size={'micro'} variant='primary' onClick={handleAddItem}>
          {t("eprReporting.addRecord")}
        </Button>
        {this.renderStepsSaveBar({
          onBackClick: () => this.handleActionDialogsOpen("reset", {}),
          onNextClick: () => this.showUploadingWarningModal(),
        })}
      </div>
    );
  }

  getCurrentProductSelections = (
    productsList,
    productId,
    categoryId,
    productTypologyId
  ) => {
    const selectedProduct =
      productId && productsList?.find(({ id }) => Number(productId) === id);
    const categoriesList = selectedProduct?.category ?? [];

    const selectedCategory =
      categoryId && categoriesList?.find(({ id }) => Number(categoryId) === id);
    const productTypologiesList = selectedCategory?.product_typologies ?? [];

    const selectedTopology =
      productTypologyId &&
      productTypologiesList?.find(({ id }) => Number(productTypologyId) === id);

    return {
      productsList,
      categoriesList,
      productTypologiesList,
      selectedProduct,
      selectedCategory,
      selectedTopology,
    };
  };

  getProductsList = () => {
    return this.state.product?.product ?? [];
  };

  step2ForTextile() {
    const { t } = this.props;
    const { textileTableData, product = {}, textileQuestions } = this.state;
    const { categories_questions } = product;
    const mappedTextileTableData = this.getTextileTableData();

    const handleAddItem = () =>
      this.handleActionDialogsOpen("textileItem", { type: "create" });

    const handleEditItem = (data) => {
      this.handleActionDialogsOpen("textileItem", {
        type: "update",
        ...data,
      });
    };

    const columns = [
      {
        property: "product",
        header: {
          label: t("eprReporting.product"),
        },
      },
      {
        property: "category",
        header: {
          label: t("eprReporting.category"),
        },
      },
      {
        property: "productTypology",
        header: {
          label: t("eprReporting.productTypologies"),
        },
      },
      {
        property: "number",
        header: {
          label: t("eprReporting.number"),
        },
      },
      {
        property: "",
        header: {
          label: "",
        },
        cell: {
          formatters: [
            (value, { rowData }) => (
              <Box
                display="flex"
                alignItems="center"
                gap="2"
              >
                <Tooltip content='Edit'>
                  <Button variant={'plain'} onClick={() => handleEditItem(rowData)}>
                    <img src={editIMG} alt='icon edit' />
                  </Button>
                </Tooltip>

                <Tooltip content='Delete'>
                  <Button
                    variant={'plain'}
                    onClick={() =>
                      this.handleActionDialogsOpen("delete", {
                        ...rowData,
                        dataKey: "textileTableData",
                        itemKey: "id",
                      })
                    }
                  >
                    <img src={deleteIMG} alt='icon delete' />
                  </Button>
                </Tooltip>
              </Box>
            ),
          ],
        },
      },
    ];

    const sortedRows = formatRow(mappedTextileTableData, columns);

    const handleQuestionRadioButtonClick = (id, value) => {
      const newAnswer = {
        id,
        value,
      };

      this.setState({
        textileQuestions: textileQuestions.map((q) => {
          if (q.id === id) {
            return newAnswer;
          }
          return q;
        }),
      });
    };

    return (
      <div>
        {this.renderBannerWithUploadTransactions()}

        {categories_questions.questions.length && (
          <Card sectioned title={categories_questions.name}>
            <Box
              display="flex"
              flexDirection="column"
              gap="2"
              width="100%"
            >
              {categories_questions.questions.map(({ id, name }) => {
                const { value = false } =
                  textileQuestions.find((q) => q.id === id) ?? {};
                return (
                    <Box
                      key={id}
                      display="flex"
                      alignItems="center"
                      gap="4"
                    >
                    <Text>{name}</Text>
                    <Box
                      display="flex"
                      alignItems="center"
                      justifyContent="center"
                      gap="2"
                    >
                      <RadioButton
                        label={t("eprReporting.yes")}
                        id={`${id}_yes`}
                        name={`${id}_yes`}
                        checked={value}
                        onChange={() =>
                          handleQuestionRadioButtonClick(id, true)
                        }
                      />
                      <RadioButton
                        label={t("eprReporting.no")}
                        checked={!value}
                        id={`${id}_no`}
                        name={`${id}_no`}
                        onChange={() =>
                          handleQuestionRadioButtonClick(id, false)
                        }
                      />
                    </Box>
                  </Box>
                );
              })}
            </Box>
          </Card>
        )}

        <Card>
          <DataTable
            columnContentTypes={["text", "text", "text", "text", "numeric"]}
            headings={columns.map(({ header }) => (
              <Text fontWeight='semibold' variant={'bodyMd'}>{header.label}</Text>
            ))}
            rows={sortedRows}
            hideScrollIndicator
            truncate
          />
          {_.isEmpty(textileTableData) && (
            <NoDataMessage
              title='There are no items'
              description={"To see items, you need to add them first"}
              buttonText={"Add items"}
              onClick={handleAddItem}
            />
          )}
        </Card>
        <br />
        <Button variant='primary'  size={'micro'}  onClick={handleAddItem}>
          {t("eprReporting.addRecord")}
        </Button>
        {this.renderStepsSaveBar({
          onBackClick: () => this.handleActionDialogsOpen("reset", {}),
          onNextClick: () => this.showUploadingWarningModal(),
        })}
      </div>
    );
  }

  step2ForPackaging() {
    const { packagingData, country } = this.state;

    const data = getPackagingSchemaForCountry({ country, t });

    return (
      <>
        {this.renderBannerWithUploadTransactions()}
        <Card sectioned>
          <FormLayout>
            <FormLayout.Group condensed>
              <Text as="h6" variant="bodyMd"  fontWeight="bold">{t("eprReporting.name")}</Text>
              <Text as="h6" variant="bodyMd"  fontWeight="bold">{t("eprReporting.kg")}</Text>
            </FormLayout.Group>
            {data.map(([label, value], i) => {
              return (
                <FormLayout.Group condensed key={value}>
                  <span>{label}</span>

                  <TextField
                    type='number'
                    step={0.01}
                    suffix={t("eprReporting.kg")}
                    value={packagingData[value]}
                    placeholder='0,00'
                    onChange={(v) => {
                      this.setState({
                        packagingData: {
                          ...this.state.packagingData,
                          [value]: v,
                        },
                      });
                    }}
                  />
                </FormLayout.Group>
              );
            })}
          </FormLayout>
        </Card>
        {this.renderStepsSaveBar({
          onBackClick: () => this.handleActionDialogsOpen("reset", {}),
          onNextClick: () => this.showUploadingWarningModal(),
        })}
      </>
    );
  }

  step3() {
    const { furnitureTableData } = this.state;
    const { t } = this.props;
    // console.log('furnitureTableData', furnitureTableData)
    return (
      <div>
        <FurniturePreview data={furnitureTableData} />

        {this.renderSummaryStepBar({
          handleBack: this.handlePrev3Step,
        })}
      </div>
    );
  }

  getTextileTableData = () => {
    const { textileTableData } = this.state;
    const productsItems = this.getProductsList();

    return textileTableData.map((item) => {
      const { selectedCategory, selectedProduct, selectedTopology } =
        this.getCurrentProductSelections(
          productsItems,
          item.product,
          item.category,
          item.productTypology
        );

      return {
        ...item,
        product: selectedProduct.name,
        category: selectedCategory.name,
        productTypology: selectedTopology.name,
      };
    });
  };

  step3ForTextile() {
    const textileTableData = this.getTextileTableData();

    return (
      <div>
        <TextilePreview data={textileTableData} />

        {this.renderSummaryStepBar({
          handleBack: this.handlePrev3Step,
        })}
      </div>
    );
  }

  step3ForPackaging() {
    const { packagingData, country } = this.state;

    return (
      <div>
        <PackagingPreview
          data={packagingData}
          tableProps={{ increasedTableDensity: false }}
          country={country}
        />

        {this.renderSummaryStepBar({
          handleBack: this.handlePrev3Step,
        })}
      </div>
    );
  }

  getUploadingProcessStatus = () => {
    return this.props.statuses.some(({ status }) => status === "in process");
  };

  renderBannerWithUploadTransactions() {
    const { period, periodType, year } = this.state;
    const { updatingEprStatusLoading, statuses, user } = this.props;
    const isUploadDisabled =
      this.getUploadingProcessStatus() || !user.has_integration;

    const inProcessStatus = statuses.filter(
      ({ status }) => status === "in process"
    );

    const handleUploadTransactions = async () => {
      await this.props.updateEpr({
        period: Number(period[0]),
        period_type: periodType,
        year,
      });

      await this.props.fetchEprStatus();
    };

    const renderBanner = ({ website, status: st, to, from, id }) => {
      const bannerStatus = st === "ready" ? "success" : "warning";

      const bannerMessage =
        st === "ready"
          ? `${website} transaction data for the period from ${formatDate(
              from
            )} to ${formatDate(to)} has been successfully updated`
          : `${website} transaction data for the period from ${formatDate(
              from
            )} to ${formatDate(to)} during the upgrade process`;

      return (
        <div key={id}>
          {/*<Banner tone={bannerStatus}>{bannerMessage}</Banner>*/}
           <BannerCustom
             status={bannerStatus}
             message={bannerMessage}
           />
        </div>
      );
    };
    // console.log('user.has_integratinProcessStatusion', inProcessStatus)
    return (
      <>
        <ButtonGroup>
          <Button
            variant='primary'
            size={'micro'}
            onClick={handleUploadTransactions}
            loading={updatingEprStatusLoading || isUploadDisabled}
            disabled={isUploadDisabled}
          >
            {t("eprReporting.uploadTransactions")}
          </Button>
        </ButtonGroup>
        <br />
        {user.has_integration ? (
          <>
            {inProcessStatus && inProcessStatus.map(renderBanner)}
            <br />
          </>
        ) : (
          <>
            <Text color='subdued' variant='bodyMd'>
              {t("eprReporting.noActiveIntegration")}
            </Text>
            <br />
          </>
        )}
      </>
    );
  }

  renderSubmittedEPRDialog() {
    const { actionDialogs, isChecked } = this.state;

    const onClose = () => {
      this.handleActionDialogsClose("submitted");
    };

    const onSubmit = () => {
      this.fillReturn("submit");

      onClose();
    };

    return (
      <Modal
        title={t("eprReporting.warning")}
        iconType={"warning"}
        description={""}
        visible={actionDialogs.submitted.open}
        onClose={onClose}
        content={
          <div style={{ maxWidth: "450px" }}>
            <br />
            <Checkbox
              label={
                <Text variant='bodyMd' color='subdued'>
                  {t("eprReporting.submitDisclaimer")}
                </Text>
              }
              checked={isChecked}
              onChange={(value) => {
                this.setState({ isChecked: value });
              }}
            />
          </div>
        }
        footer={
          <ButtonGroup fullWidth>
            <Button onClick={onClose}>{t("vatReturns.cancel")}</Button>
            <Button variant='primary' onClick={onSubmit} disabled={!isChecked}>
              {t("eprReporting.submit")}
            </Button>
          </ButtonGroup>
        }
      />
    );
  }

  renderCreatedEPRDialog() {
    const { actionDialogs } = this.state;
    const { addingResult = false } = actionDialogs?.cellData ?? {};

    const onClose = () => {
      this.handleActionDialogsClose("created");
      this.props.navigate("/epr-reporting");
    };

    const getDescription = (finalType) => {
      if (finalType === "create") {
        return t("eprReporting.eprReportSuccessfullyCreated");
      } else {
        return t("eprReporting.eprReportSuccessfullySubmitted");
      }
    };

    return (
      actionDialogs.cellData &&
      addingResult && (
        <Modal
          title={
            addingResult.message
              ? t("eprReporting.warning")
              : t("eprReporting.success")
          }
          iconType={addingResult.message ? "warning" : "success"}
          description={
            !addingResult.message
              ? getDescription(actionDialogs.cellData.finalType)
              : ""
          }
          visible={actionDialogs.created.open}
          onClose={onClose}
          content={
            addingResult.message && (
              <p>
                {addingResult.message} (
                {
                  <Link className='Polaris-Link' to='/subscription'>
                    {t("createReturns.changeSubPlan")}
                  </Link>
                }
                )
              </p>
            )
          }
          footer={
            <Button variant='primary' size='large' onClick={onClose}>
              &nbsp;&nbsp;&nbsp;&nbsp;Ok&nbsp;&nbsp;&nbsp;&nbsp;
            </Button>
          }
        />
      )
    );
  }

  renderUploadingWarningDialog() {
    const { actionDialogs, uploadingWarningConfirm } = this.state;

    const onClose = () => {
      this.handleActionDialogsClose("uploadingProgress");
    };

    const onSubmit = () => {
      onClose();

      this.handleNext();
    };

    return (
      <Modal
        title={t("eprReporting.transactionsUploadInProgress")}
        contentOnCenter
        iconType={"warning"}
        visible={actionDialogs.uploadingProgress.open}
        onClose={onClose}
        content={
          <div style={{ maxWidth: "400px" }}>
            <br />
            <Checkbox
              label={
                <Text variant='bodyMd' color='subdued'>
                  {t("eprReporting.confirmMoveForwardWithUnfinishedUpload")}
                </Text>
              }
              checked={uploadingWarningConfirm}
              onChange={(value) => {
                this.setState({ uploadingWarningConfirm: value });
              }}
            />
          </div>
        }
        footer={
          <ButtonGroup fullWidth>
            <Button size='large' onClick={onClose}>
              {t("eprReporting.cancel")}
            </Button>
            <Button
              variant='primary'
              size='large'
              onClick={onSubmit}
              disabled={!uploadingWarningConfirm}
            >
              {t("eprReporting.submit")}
            </Button>
          </ButtonGroup>
        }
      />
    );
  }

  renderDeleteItemDialog() {
    const { actionDialogs } = this.state;

    const onClose = () => {
      this.handleActionDialogsClose("delete");
    };

    const { dataKey, itemKey } = actionDialogs.cellData ?? {};

    const onSubmit = () => {
      const newTableData = this.state[dataKey].filter(
        (item) => item[itemKey] !== actionDialogs.cellData?.[itemKey] ?? {}
      );

      this.setState({
        [dataKey]: newTableData,
      });

      onClose();
    };

    return (
      <Modal
        title={t("eprReporting.delete")}
        contentOnCenter
        iconType={"danger"}
        description={t("eprReporting.areYouSureToDelete")}
        visible={actionDialogs.delete.open}
        onClose={onClose}
        footer={
          <ButtonGroup fullWidth>
            <Button size='large' onClick={onClose}>
              {t("eprReporting.cancel")}
            </Button>
            <Button tone={'critical'}
                    variant={'plain'} size='large' onClick={onSubmit}>
              {t("eprReporting.delete")}
            </Button>
          </ButtonGroup>
        }
      />
    );
  }

  renderTextileItemInfo = () => {
    const { t } = this.props;
    const { actionDialogs, textileSelectFields, textileTableData } = this.state;
    const productsItems = this.getProductsList();

    const isAddRecordButtonDisabled = !Object.values(textileSelectFields).every(
      (item) => item
    );

    const { category, productTypology, product, number } = textileSelectFields;
    const onAddRecord = () => {
      const newItem = { id: uniqueId(), ...textileSelectFields };
      
      this.setState({
        textileTableData: [...textileTableData, newItem],
      });
    };

    const onEditRecord = () => {
      const newTextileTableData = textileTableData.map((item) => {
        if (item.id === actionDialogs.cellData.id) {
          return {
            ...item,
            ...textileSelectFields,
          };
        }
        return item;
      });
      this.setState({
        textileTableData: newTextileTableData,
      });
    };

    const getModalPropertiesByType = (type) => {
      switch (type) {
        case "create":
          return {
            title: t("eprReporting.addRecord"),
            submitAction: onAddRecord,
            submitButton: t("eprReporting.addRecord"),
          };
        case "update":
          return {
            title: t("eprReporting.edit"),
            submitAction: onEditRecord,
            submitButton: t("eprReporting.edit"),
          };
        default:
          return {};
      }
    };
    const { title, submitAction, submitButton } = getModalPropertiesByType(
      actionDialogs.cellData?.type
    );

    const onClose = () => {
      this.handleActionDialogsClose("textileItem");
    };

    const onSubmit = () => {
      submitAction();
      onClose();
    };

    const arrayToSelectOptions = (array) => {
      if (!_.isEmpty(array)) {
        return [
          { value: "", label: "" },
          ...array.map(({ id, name }) => ({
            value: id.toString(),
            label: name,
          })),
        ];
      }
      return [];
    };

    const { productsList, categoriesList, productTypologiesList } =
      this.getCurrentProductSelections(
        productsItems,
        product,
        category,
        productTypology
      );

    const onProductChange = (value) => {
      this.setState({
        textileSelectFields: {
          ...textileSelectFields,
          product: value,
          category: "",
          productTypology: "",
        },
      });
    };

    const onCategoriesChange = (value) => {
      this.setState({
        textileSelectFields: {
          ...textileSelectFields,
          category: value,
          productTypology: "",
        },
      });
    };

    const onProductTypologyChange = (value) => {
      this.setState({
        textileSelectFields: {
          ...textileSelectFields,
          productTypology: value,
        },
      });
    };

    const onNumberChange = (value) => {
      this.setState({
        textileSelectFields: {
          ...textileSelectFields,
          number: value,
        },
      });
    };

    const getLabelFor = (label, active) => (
      <Text fontWeight={active ? "regular" : "medium"}>
        {t(`eprReporting.${label}`)}
      </Text>
    );

    return (
      <Modal
        title={title}
        infoIconVisible={false}
        visible={actionDialogs.textileItem.open}
        onClose={onClose}
        content={
          <>
            <br />
            <FormLayout>
              <FormLayout.Group>
                <Select
                  label={getLabelFor("product", _.isEmpty(productsList))}
                  options={arrayToSelectOptions(productsList)}
                  onChange={onProductChange}
                  value={product}
                />
                <Select
                  label={getLabelFor("category", _.isEmpty(categoriesList))}
                  disabled={!categoriesList.length}
                  options={arrayToSelectOptions(categoriesList)}
                  onChange={onCategoriesChange}
                  value={category}
                />
              </FormLayout.Group>
              <FormLayout.Group>
                <Select
                  label={getLabelFor(
                    "productTypology",
                    _.isEmpty(productTypologiesList)
                  )}
                  disabled={!productTypologiesList.length}
                  options={arrayToSelectOptions(productTypologiesList)}
                  onChange={onProductTypologyChange}
                  value={productTypology}
                />
                <TextField
                  id='number'
                  label={getLabelFor("number", true)}
                  type='number'
                  value={number}
                  onChange={onNumberChange}
                />
              </FormLayout.Group>
            </FormLayout>
          </>
        }
        footer={
          <ButtonGroup fullWidth>
            <Button onClick={onClose}>{t("eprReporting.cancel")}</Button>
            <Button
              variant='primary'
              disabled={isAddRecordButtonDisabled}
              onClick={onSubmit}
            >
              {submitButton}
            </Button>
          </ButtonGroup>
        }
      />
    );
  };

  renderFurnitureItemInfo = () => {
    const { eprFurniture, creatingEprFurnitureEcoCode, t } = this.props;
    const {
      actionDialogs,
      furnitureSelectFields,
      furnitureTableData,
      country,
    } = this.state;
   
    const isAddRecordButtonDisabled = !Object.values(
      furnitureSelectFields
    ).every((item) => item);

    const { category, feature, material, product, number, name, code } =
      furnitureSelectFields;

    const { categories, features, materials, products } = eprFurniture;
   
    const createNewTableItem = (item) => {
      const { code, summ, ...restFurnitureSelectFields } =
        furnitureSelectFields;

      return {
        ...item,
        summ: item.Tarif_HT * number,
        number,
        name: item.description,
        ...restFurnitureSelectFields,
      };
    };

    const onAddRecord = async () => {
      const furnitureEcoCode = {
        category_code: category,
        product_type_code: product,
        material_code: material,
        characteristic_code: feature,
        name: name,
      };
      
      const normalizedFurnitureTableData = Array.isArray(furnitureTableData)
        ? furnitureTableData
        : [];
      
      await this.props
        .createEprFurnitureEcoCode(furnitureEcoCode)
        .then((res) => {
          this.setState({
            furnitureTableData: [
              ...normalizedFurnitureTableData,
              createNewTableItem(res),
            ],
            furnitureSelectFields: {},
          });
        });
    };

    const onEditRecord = async () => {
      const furnitureEcoCode = {
        category_code: category,
        product_type_code: product,
        material_code: material,
        characteristic_code: feature,
        name: name,
      };

      await this.props
        .createEprFurnitureEcoCode(furnitureEcoCode)
        .then((res) => {
          const newFurnitureTableData = furnitureTableData?.map((item) => {
            if (item.code === code) {
              return createNewTableItem(res);
            }
            return item;
          });
          this.setState({
            furnitureTableData: newFurnitureTableData,
            furnitureSelectFields: {},
          });
        });
    };

    const getModalPropertiesByType = (type) => {
      switch (type) {
        case "create":
          return {
            loading: creatingEprFurnitureEcoCode,
            title: t("eprReporting.addRecord"),
            submitAction: onAddRecord,
            submitButton: t("eprReporting.addRecord"),
          };
        case "update":
          return {
            loading: creatingEprFurnitureEcoCode,
            title: `Edit item with code: ${code}`,
            submitAction: onEditRecord,
            submitButton: t("eprReporting.edit"),
          };
        default:
          return {};
      }
    };
    const { loading, title, submitAction, submitButton } =
      getModalPropertiesByType(actionDialogs.cellData?.type);

    const onClose = () => {
      this.handleActionDialogsClose("furnitureItem");
    };

    const onSubmit = async () => {
      const res = await submitAction();
      // console.log( res)
      onClose();
    };

    const arrayToSelectOptions = (array) => {
      if (array) {
        return [
          { value: "", label: "" },
          ...array.map(({ code, name }) => ({
            value: code.toString(),
            label: name,
          })),
        ];
      }
      return [];
    };

    const onCategoriesChange = (value) => {
      this.setState({
        furnitureSelectFields: {
          ...furnitureSelectFields,
          category: value,
          product: "",
          material: "",
          feature: "",
        },
      });

      if (value) {
        this.props.fetchEprFurnitureProducts(country, value);
      }
    };

    const onProductChange = (value) => {
      this.setState({
        furnitureSelectFields: {
          ...furnitureSelectFields,
          product: value,
          material: "",
          feature: "",
        },
      });
      if (value) {
        this.props.fetchEprFurnitureMaterials(country, category, value);
      }
    };

    const onMaterialsChange = (value) => {
      this.setState({
        furnitureSelectFields: {
          ...furnitureSelectFields,
          material: value,
          feature: "",
        },
      });

      if (value) {
        this.props.fetchEprFurnitureFeatures(country, category, product, value);
      }
    };

    const onFeaturesChange = (value) =>
      this.setState({
        furnitureSelectFields: {
          ...furnitureSelectFields,
          feature: value,
          name:
            features.find(({ code }) => code.toString() === value)?.name || "",
        },
      });

    const onNumberChange = (value) =>
      this.setState({
        furnitureSelectFields: {
          ...furnitureSelectFields,
          number: value,
        },
      });

    const getLabelFor = (label, active) => (
      <Text fontWeight={active ? "regular" : "medium"}>
        {t(`eprReporting.${label}`)}
      </Text>
    );

    return (
      <Modal
        title={title}
        infoIconVisible={false}
        visible={actionDialogs.furnitureItem.open}
        onClose={onClose}
        content={
          <>
            <br />
            <FormLayout>
              <FormLayout.Group>
                <Select
                  label={getLabelFor("category", _.isEmpty(categories))}
                  disabled={!categories}
                  options={arrayToSelectOptions(categories)}
                  onChange={onCategoriesChange}
                  value={category}
                />
                <Select
                  label={getLabelFor("product", _.isEmpty(products))}
                  disabled={!products}
                  options={arrayToSelectOptions(products)}
                  onChange={onProductChange}
                  value={product}
                />
              </FormLayout.Group>
              <FormLayout.Group>
                <Select
                  label={getLabelFor("material", _.isEmpty(materials))}
                  disabled={!materials}
                  options={arrayToSelectOptions(materials)}
                  onChange={onMaterialsChange}
                  value={material}
                />
                <Select
                  label={getLabelFor("feature", _.isEmpty(features))}
                  disabled={!features}
                  options={arrayToSelectOptions(features)}
                  onChange={onFeaturesChange}
                  value={feature}
                />
              </FormLayout.Group>
              <FormLayout.Group>
                <TextField
                  id='number'
                  label={getLabelFor("number", true)}
                  type='number'
                  value={number}
                  onChange={onNumberChange}
                />
                <br />
              </FormLayout.Group>
            </FormLayout>
          </>
        }
        footer={
          <ButtonGroup fullWidth>
            <Button onClick={onClose}>{t("eprReporting.cancel")}</Button>
            <Button
              variant='primary'
              disabled={isAddRecordButtonDisabled}
              loading={loading}
              onClick={onSubmit}
            >
              {submitButton}
            </Button>
          </ButtonGroup>
        }
      />
    );
  };

  getDeclarationTitle = () => {
    const { year, period, country, countries, periodType } = this.state;
    const type = this.getCurrentReportType().name;
    const reportCountry = countries.find(
      ({ value }) => value === country
    ).label;

    let renderPeriod = "";

    if (periodType === "year") {
      renderPeriod = `${periodType} ${year}`;
    } else {
      renderPeriod = `${period[0]} ${periodType} ${year}`;
    }

    return `${type} for ${renderPeriod} for ${reportCountry}`;
  };

  renderTitle = (isFirstStep) =>
    !isFirstStep && (
      <>
        <Text variant='bodyMd'>{this.getDeclarationTitle()}</Text>
        <br />
      </>
    );

  render() {
    const { stepIndex, createEprDeclarationError } = this.state;
    const steps = this.getStepsForTypeOfReport();
    const isFirstStep = stepIndex === 0;

    return (
      <Page
        fullWidth
        title={
          <GoBackPageNavigation content={
            <Text variant='heading3xl' as='span'>
              {t("eprReporting.eprReport")}
            </Text>
          } />
        }
      >
        
        <PageHelmet title={"EPR Report"} />

        <div>
          <div style={!isFirstStep ? {width:'100%', display: 'flex', alignItems: 'flex-start', justifyContent: 'space-between', padding: '0 0 0 30px'} : {width: '100%', justifyContent: 'flex-end', padding: '0 0 0 30px'}}>
          {!isFirstStep && (
                <div>
                  <Stepper
                    steps={_.map(steps, (step) => {
                      return {
                        title: step.name,
                        icon: step1IMG,
                      };
                    })}
                    activeStep={stepIndex}
                    titleFontSize={14}
                    activeColor={'#E4F3FE'}
                    completeColor={'#216DC5'}
                    circleFontColor={'#212B36'}
                    defaultTitleColor={'rgba(0, 0, 0, 1)'}
                    completeTitleColor={'rgba(0, 0, 0, 1)'}
                    activeTitleColor={'#216DC5'}
                    completeBorderColor={"#367C41"}
                    defaultBorderWidth={2}
                    defaultBarColor={'#ADADAD'}
                    size={24}
                    circleFontSize={14}
                  />
                </div>
              )}
              <div style={!isFirstStep ? {width: '70%', marginBottom: '102px'} : null}>
                  {this.renderTitle(isFirstStep)}
                  {this.getStepContent()}
                  {createEprDeclarationError && (
                    <>
                      <br />
                      {/*<Banner tone='critical'>*/}
                      {/*  {"Error! " + createEprDeclarationError}*/}
                      {/*</Banner>*/}
                      <BannerCustom
                        status={'critical'}
                        message={"Error! " + createEprDeclarationError}
                      />
                    </>
                  )}
              </div>
            </div>
            
        </div>
        {this.renderSubscriptionDialog()}
        {this.renderResetDialog()}
        {this.renderCreatedEPRDialog()}
        {this.renderSubmittedEPRDialog()}
        {this.renderDeleteItemDialog()}
        {this.renderUploadingWarningDialog()}
        {this.renderFurnitureItemInfo()}
        {this.renderTextileItemInfo()}
      </Page>
    );
  }
}

const mapStateToProps = (state) => ({
  user: state.user.user,
  eprOperators: state.declarations.eprOperators,
  eprDeclaration: state.declarations.eprDeclaration,
  eprFurniture: state.declarations.eprFurniture,
  creatingEprFurnitureEcoCode: state.declarations.creatingEprFurnitureEcoCode,
  eprCategories: state.declarations.filtersEPR,
  updatingEprStatusLoading: state.declarations.updatingEprStatusLoading,
  statuses: state.declarations.statuses,
  eprSubcategoriesResult: state.declarations.eprSubcategories,
});

const mapDispatchToProps = (defaultDispatch) => {
  const dispatch = createCustomDispatch(defaultDispatch);

  return {
    fetchEPROperators: () => dispatch(fetchEPROperators()),
    resetSystemMessage: (data) => dispatch(resetSystemMessage(data)),
    fetchEprStatus: () => dispatch(fetchEprStatus()),
    createEprDeclaration: (params) => dispatch(createEprDeclaration(params)),
    fetchEprFurnitureChoice: (code) => dispatch(fetchEprFurnitureChoice(code)),
    updateEpr: (data) => dispatch(updateEpr(data)),
    fetchEprCountries: () => dispatch(fetchEprCountries()),
    fetchFiltersEPR: () => dispatch(fetchFiltersEPR()),
    createEprFurnitureEcoCode: (params) =>
      dispatch(createEprFurnitureEcoCode(params)),
    fetchEprFurnitureCategories: (params) =>
      dispatch(fetchEprFurnitureCategories(params)),
    fetchEprFurnitureProducts: (country_code, category_code) =>
      dispatch(fetchEprFurnitureProducts({ country_code, category_code })),
    fetchEprFurnitureMaterials: (country_code, category_code, product_code) =>
      dispatch(
        fetchEprFurnitureMaterials({
          country_code,
          category_code,
          product_code,
        })
      ),
    fetchEprFurnitureFeatures: (
      country_code,
      category_code,
      product_code,
      material_code
    ) =>
      dispatch(
        fetchEprFurnitureFeatures({
          country_code,
          category_code,
          product_code,
          material_code,
        })
      ),
    setResetData: (params) => dispatch(setResetData(params)),
    setResetEprDeclaration: (params) => dispatch(setResetEprDeclaration(params)),
  };
};

export default withTranslation()(
  connect(mapStateToProps, mapDispatchToProps)(withRouter(AddEPRReporting))
);
