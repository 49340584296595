import { Button, DataTable, Spinner, Text, Tooltip } from '@shopify/polaris';
import React from 'react';
import NoDataMessage from '../../../components/NoDataMessage';
import deleteIMG from "img/delete.svg";
import editIMG from "img/edit.svg";

const TablePeppol = ({dataTable, loadingTable, onEdit, onDelete, user}) => {
	const columns = [
		{key: '#', label: '#'},
		{key: 'Company Name', label: 'Company Name'},
		{key: 'country', label: 'Country'},
		{key: 'peppol_number', label: 'Peppol Number'},
		{key: 'activate', label: 'Active'},
		{key: 'actions', label: ''},
	];
	
	const rows = dataTable?.map((item, index) => [
		index + 1,
		user?.company?.name ? user?.company?.name : '-',
		item.country,
		item.peppol_number,
		item.activate
			?
			<span style={{display: 'flex'}}>
		    <svg
			    xmlns='http://www.w3.org/2000/svg'
			    width='18'
			    height='13'
			    viewBox='0 0 18 13'
			    fill='none'
		    >
					<path
						d='M17 1L6 12L1 7'
						stroke='#489F56'
						strokeWidth='1.5'
						strokeLinecap='round'
						strokeLinejoin='round'
					/>
					</svg >
	    </span >
			:
			<span style={{display: 'flex'}}>
		    <svg
			    xmlns='http://www.w3.org/2000/svg'
			    width='14'
			    height='14'
			    viewBox='0 0 14 14'
			    fill='none'
		    >
					<path
						d='M13 1L1 13M1 1L13 13'
						stroke='#D92D20'
						strokeWidth='1.5'
						strokeLinecap='round'
						strokeLinejoin='round'
					/>
					</svg >
	    </span >,
		<div style={{display: 'flex', gap: '8px', justifyContent: 'flex-end'}}>
			<div style={{marginRight: 20}}>
				<Tooltip content='Edit'>
        <Button
	        variant='plain'
	        onClick={() => onEdit(item)}
        >
          <img
	          src={editIMG}
	          alt='Edit'
          />
        </Button >
      </Tooltip >
			</div >
      <Tooltip content='Delete'>
        <Button
	        variant='plain'
	        onClick={() => onDelete(item)}
        >
          <img
	          src={deleteIMG}
	          alt='Delete'
          />
        </Button >
      </Tooltip >
    </div >,
	]) || [];
	
	return (
		<div style={{width: '100%'}}>
      {loadingTable ? (
	      <div style={{display: 'flex', justifyContent: 'center', padding: '20px'}}>
          <Spinner />
        </div >
      ) : rows.length > 0 ? (
	      <DataTable
		      columnContentTypes={['text', 'text', 'text', 'text', 'text', 'text']}
		      headings={columns.map(({label}) => (
			      <Text
				      fontWeight='semibold'
				      variant='bodyMd'
			      >
              {label}
            </Text >
		      ))}
		      rows={rows}
	      />
      ) : (
	      <NoDataMessage
		      description='No data available'
		      buttonText='Refresh'
	      />
      )}
    </div >
	);
};

export default TablePeppol;
