import { Button, Card, Checkbox, DataTable, Spinner, Text, Tooltip } from '@shopify/polaris'
import React from 'react'
import { useTranslation } from 'react-i18next'
import BadgeCustom from '../../components/Badges/BadgeCustom'
import NoDataMessage from '../../components/NoDataMessage'
import deleteIMG from '../../img/delete.svg'
import editIMG from '../../img/edit.svg'
import formatRow from '../../utils/formatRow'
import './style.scss'

const EDocumentsTable = ({edocuments, loading, selectedRows, handleSelectAll, handleRowSelect}) => {
	const {t} = useTranslation()
	const rows = edocuments || []
	
	const checkedStyle = (value) => {
		return selectedRows?.includes(value?.id)
	}
	
	const getStatus = (status) => {
		switch (status) {
			case "ban":
				return "critical";
			case "active":
				return "success";
			default:
				return "attention";
		}
	};
	
	const columns = [
		{
			property: "checkbox",
			header: {
				label: (
					<Checkbox
						label=''
						checked={
							selectedRows?.length > 0 &&
							selectedRows?.length === edocuments?.length
						}
						onChange={handleSelectAll}
					/>
				),
			},
			cell: {
				formatters: [
					(value,
						{rowData}) => (
						<Checkbox
							label=''
							checked={selectedRows?.includes(rowData?.id)}
							onChange={() => handleRowSelect(rowData?.id)}
						/>
					),
				],
			},
		},
		{
			property: "inv_number",
			header: {
				label: 'Invoice ID',
			},
		},
		{
			property: 'customer_peppol_id',
			header: {
				label: 'Sender Peppol',
			},
		},
		{
			property: 'supplier_peppol_id',
			header: {
				label: 'Receiver Peppol ID',
			},
		},
		{
			property: 'created_date',
			header: {
				label: 'Transaction Date',
			},
		},
		{
			property: "invoice_type",
			header: {
				label: 'Transaction Type',
			},
		},
		{
			property: "status",
			header: {
				label: 'Status',
			},
			cell:
				{
					formatters: [
						(value,
							{rowData}) => <BadgeCustom content={value} />
					],
				},
		},
		// {
		// 	property: "direction",
		// 	header: {
		// 		label: 'Direction',
		// 	},
		// },
		{
			property: "customer",
			header: {
				label: 'Buyer Name',
			},
		},
		{
			property: "supplier",
			header: {
				label: 'Seller Name',
			},
		},
		{
			property: "customer_country_id",
			header: {
				label: 'Buyer Country',
			},
		},
		{
			property: "currency_id",
			header: {
				label: 'Currency',
			},
		},
		{
			property: "subtotal",
			header: {
				label: 'Subtotal',
			},
		},
		{
			property: "tax",
			header: {
				label: 'Tax',
			},
		},
		{
			property: "total",
			header: {
				label: 'Total',
			},
		},
		// {
		// 	property: "customer_country_id", // или supplier_country_id — смотри, что хочешь показать
		// 	header: {
		// 		label: t("vatReturns.country"),
		// 	},
		// 	cell: {
		// 		formatters: [
		// 			(value, { rowData }) => `${rowData.customer_country_id || "-"}`
		// 		],
		// 	},
		// },
		// {
		// 	property:'Actions',
		// 	header: {
		// 		label: '',
		// 	},
		// 	cell: {
		// 		formatters: [
		// 			(value, {rowData}) => {
		// 				return (
		// 					<div
		// 						style={{
		// 							display: "flex",
		// 							alignItems: "center",
		// 							gap: "2",
		// 						}}
		// 					>
		// 	      <Tooltip content={t("vatReturns.edit")}>
		// 	        <Button
		// 		        variant='plain'
		// 		        icon={<img
		// 			        src={editIMG}
		// 			        alt='edit'
		// 		        />}
		// 		        onClick={() => editCustomerHandler(rowData)}
		// 	        />
		// 	      </Tooltip >
		// 	      <Tooltip content={t("vatReturns.delete")}>
		// 	        <Button
		// 		        variant='plain'
		// 		        icon={<img
		// 			        src={deleteIMG}
		// 			        alt='delete'
		// 		        />}
		// 		        onClick={() => deleteCustomerHandler(rowData)}
		// 	        />
		// 	      </Tooltip >
		// 	    </div >
		// 				)
		// 			}
		// 		]
		// 	}
		// },
	]
	
	const sortedRows = formatRow(rows, columns)
	
	return (
		<Card >
        {loading ? (
	        <div >
            <Spinner />
          </div >
        ) : (
	        <div className='changeLineInTableColor edocuments-table-style'>
		        <DataTable
			        columnContentTypes={Array(columns?.length).fill('text')}
			        verticalAlign='center'
			        stickyHeader={false}
			        headings={columns?.map(({header}) => (
				        <Text
					        fontWeight='semibold'
					        key={header.label}
				        >
					        {header.label}
								</Text >
			        ))}
			        rows={sortedRows}
		        />
		        {sortedRows.length === 0 && (
			        <NoDataMessage
				        description={"To see documents in the list, first add them."}
				        buttonText={"Add Documents from templates"}
			        />
		        )}
          </div >
        )}
      </Card >
	)
}

export default EDocumentsTable