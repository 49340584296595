import React from 'react'
import { useSelector } from 'react-redux'
import { Navigate, Route, Routes } from 'react-router-dom'
import Report1099 from '../containers/1099'
import AddClient from '../containers/AddClient/AddClient'
import AddMessage from '../containers/AddMessage'
import Addons from '../containers/Addons/Addons'
import Activation from '../containers/Authentication/screens/Activation/Activation'
import ForgotPassword from '../containers/Authentication/screens/ForgotPassword/ForgotPassword'
import LogIn from '../containers/Authentication/screens/LogIn'
import LogInAdmin from '../containers/Authentication/screens/LogInAdmin/LogInAdmin'
import SignUp from '../containers/Authentication/screens/SignUp/SignUp'
import Billing from '../containers/Billing'
import AddInvoice from '../containers/Billing/AddInvoice'
import AddPayment from '../containers/Billing/AddPayment'
import Case from '../containers/Case/Case'
import Clients from '../containers/Clients'
import CodeConfirmation from '../containers/CodeConfirmation'
import Dac7Report from '../containers/Dac7'
import Dialog from '../containers/Dialog/Dialog'
import Documents from '../containers/Documents'
import AddExemption from '../containers/Documents/ExemptionCertificates/AddExemption/AddExemption'
import CreateExemption from '../containers/Documents/ExemptionCertificates/CreateExemption/CreateExemption'
import DocumentTemplate from '../containers/DocumentTemplate/DocumentTemplate'
import EPRReporting from '../containers/EPRReporting'
import AddEPRReporting from '../containers/EPRReporting/AddEPRReporting'
import HelpCenter from '../containers/HelpCenter'
import Message from '../containers/Message'
import Messages from '../containers/Messages'
import Profile from '../containers/Profile'
import Reports from '../containers/Reports/Reports'
import CreateTaxReturn from '../containers/SalesTaxReturns/CreateTaxReturn'
import SalesTaxReturns from '../containers/SalesTaxReturns/SalesTaxReturns'
import AddStore from '../containers/Stores/AddStore'
import Stores from '../containers/Stores/Stores'
import EDocuments from '../EinvoiceProject/E-Documents/EDocuments'
import Subscription from '../EinvoiceProject/EinvoiceSubscription/Subscription'
import TaxSettings from '../containers/TaxSettings/TaxSettings'
import AddTransactions from '../containers/Transactions/AddTransactions'
import Transactions from '../containers/Transactions/Transactions'
import Unsubscribe from '../containers/Unsubscribe'
import VATPayment from '../containers/VATPayment/VATPayment'
import OrderRegistration from '../containers/VatRegistration/OrderRegistration'
import CreateReturn from '../containers/VATReturns/CreateReturn'
import VATReturns from '../containers/VATReturns/VATReturns'
import AddCustomer from '../EinvoiceProject/Customers/AddCustomer'
import Customers from '../EinvoiceProject/Customers/Customers'
import LookUp from '../EinvoiceProject/LookUp/LookUp'
import Settings from '../EinvoiceProject/Settings/Settings'
import { getIsManager, getIsOMP, getIsTaxAgentMainStatus, isUserTaxAgentOrManager } from '../redux/selectors'

// function Documents() {
// 	return null
// }

const RoutesForEinvoice = ({isLogged, isLoggingIn}) => {
	const state = useSelector((state) => state);
	const IS_TAX_AGENT_MAIN_STATUS = getIsTaxAgentMainStatus(state);
	const IS_MANAGER = getIsManager(state);
	const IS_TAX_AGENT_OR_MANAGER = isUserTaxAgentOrManager(state.user.user);
	const IS_SHOW_VAT_RETURNS_IN_MENU = state.user.user.vat_returns_show_in_menu;
	const IS_SHOW_SALES_TAX_RETURNS_IN_MENU =
		state.user.user.sales_tax_returns_show_in_menu;
	const IS_OMP = getIsOMP(state);
	const IS_DAC7_ENABLED = state.user.user.dac7;
	
	const commonRoutes = (
		<>
      <Route
	      path='/unsubscribe/:token'
	      exact
	      element={<Unsubscribe />}
      />
    </>
	);
	
	const notLoggedInRoutes = (
		<>
      <Route
	      path='/login'
	      exact
	      element={<LogIn />}
      />
      <Route
	      path='/signup'
	      exact
	      element={<SignUp />}
      />
      <Route
	      path='/manager'
	      exact
	      element={<LogInAdmin />}
      />
      <Route
	      path='/forgot'
	      exact
	      element={<ForgotPassword />}
      />
      <Route
	      path='/forgot/:code'
	      element={<ForgotPassword />}
      />
      <Route
	      path='/activation/:code'
	      element={<Activation />}
      />
      <Route
	      path='/confirm-2fa'
	      element={<CodeConfirmation />}
      />
			{isLoggingIn && <Route
				path='*'
				element={<Navigate to='/login' />}
			/>}
    </>
	);
	
	const loggedInRoutes = (
		<>
			<Route
				path='/lookUp'
				exact
				element={<LookUp />}
			/>
			<Route
				path='/'
				exact
				element={<Documents />}
			/>
      <Route
	      path='/profile'
	      exact
	      element={<Profile />}
      />
			{/*<Route path='/settings' exact element={<Profile />} />*/}
			<Route
				path='/billing'
				exact
				element={<Billing />}
			/>
      <Route
	      path='/billing/add-payment'
	      exact
	      element={<AddPayment />}
      />
      <Route
	      path='/billing/add-invoice'
	      exact
	      element={<AddInvoice />}
      />

      <Route
	      path='/transactions'
	      exact
	      element={<Transactions />}
      />
      <Route
	      path='/transactions/import'
	      exact
	      element={<AddTransactions />}
      />
      <Route
	      path='/integrations'
	      exact
	      element={<Stores />}
      />
      <Route
	      path='/integrations/add'
	      exact
	      element={<AddStore />}
      />
      <Route
	      path='/reports'
	      exact
	      element={<Reports />}
      />
      <Route
	      path='/settings'
	      exact
	      element={<Settings />}
      />
      {/*<Route*/}
	    {/*  path='/settings/order'*/}
	    {/*  exact*/}
	    {/*  element={<OrderRegistration />}*/}
      {/*/>*/}
      <Route
	      path='/subscription'
	      exact
	      element={<Subscription />}
      />
      <Route
	      path='/addons'
	      exact
	      element={<Addons />}
      />
      <Route
	      path='/messages'
	      exact
	      element={<Messages />}
      />
      <Route
	      path='/messages/add'
	      exact
	      element={<AddMessage />}
      />
      <Route
	      path='/messages/:messageId'
	      exact
	      element={<Message />}
      />

      <Route
	      path='/help'
	      exact
	      element={<HelpCenter />}
      />
      <Route
	      path='/help/messenger/:dialogId'
	      exact
	      element={<Dialog />}
      />
      <Route
	      path='/help/messenger/case'
	      exact
	      element={<Case />}
      />
			{IS_SHOW_VAT_RETURNS_IN_MENU && (
				<>
          <Route
	          path='/vat-returns'
	          exact
	          element={<VATReturns />}
          />
          <Route
	          path='/vat-payment'
	          exact
	          element={<VATPayment />}
          />
          <Route
	          path='/vat-returns/create-return'
	          exact
	          element={<CreateReturn />}
          />
          <Route
	          path='/vat-returns/create-return/:id/:countryId/:countryName/:period/:periodType/:year/:reason/:typeOfReport'
	          exact
	          element={<CreateReturn />}
          />
        </>
			)}
			{!IS_TAX_AGENT_MAIN_STATUS && IS_SHOW_SALES_TAX_RETURNS_IN_MENU && (
				<>
          <Route
	          path='/tax-returns'
	          exact
	          element={<SalesTaxReturns />}
          />
          <Route
	          path='/tax-returns/create-return'
	          exact
	          element={<CreateTaxReturn />}
          />
        </>
			)}
			
			{IS_TAX_AGENT_OR_MANAGER && (
				<>
          <Route
	          path='/clients'
	          exact
	          element={<Clients />}
          />
          <Route
	          path='/clients/add-client'
	          exact
	          element={<AddClient />}
          />
        </>
			)}
			<Route
				path='/epr-reporting'
				exact
				element={<EPRReporting />}
			/>
      <Route
	      path='/epr-reporting/add'
	      exact
	      element={<AddEPRReporting />}
      />
      <Route
	      path='/epr-reporting/add/:id/:countryId/:year/:reportType'
	      exact
	      element={<AddEPRReporting />}
      />

      <Route
	      path='/e-documents'
	      exact
	      element={<EDocuments />}
      />
      <Route
	      path='/documents'
	      exact
	      element={<Documents />}
      />
      <Route
	      path='/documents/:type/create'
	      exact
	      element={<DocumentTemplate />}
      />
      <Route
	      path='/documents/exemption/add'
	      exact
	      element={<AddExemption />}
      />
			<Route
				path='/documents/exemption/create'
				exact
				element={<CreateExemption />}
			/>
			
			<Route
				path='/e-documents'
				exact
				element={<Documents />}
			/>
			<Route
				path='/customers'
				exact
				element={<Customers />}
			/>
			<Route
				path='/customers/create'
				exact
				element={<AddCustomer />}
			/>
			
			{IS_OMP && IS_DAC7_ENABLED && (
				<Route
					path='/dac7-reporting'
					exact
					element={<Dac7Report />}
				/>
			)}
			
			{IS_OMP && IS_DAC7_ENABLED && (
				<Route
					path='/dac7-immovable-property'
					exact
					element={<Dac7Report isImmovableProperty={true} />}
				/>
			)}
			
			<Route
				path='/return-1099'
				exact
				element={<Report1099 />}
			/>

      <Route
	      path='*'
	      element={<Navigate to='/profile' />}
      />
		</>
	)
	
	return (
		<Routes >
      {commonRoutes}
			{isLogged ? loggedInRoutes : notLoggedInRoutes}
    </Routes >
	)
}

export default RoutesForEinvoice