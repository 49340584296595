import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import callApi from "helpers/callApi";
import { parseServerError } from "utils/errors";


export const fetchCustomers = createAsyncThunk(
	"customers/fetchCustomers",
	async (params,
		{rejectWithValue}) => {
		try {
			const response = await callApi("/documents/customers", "GET");
			if (!response.ok) {
				throw new Error("Server error");
			}
			const data = await response.json();
			return data;
		} catch (error) {
			return rejectWithValue(error.message);
		}
	}
);

export const getLanguagesList = createAsyncThunk(
	"customers/getLanguagesList",
	async (params,
		{rejectWithValue}) => {
		try {
			const response = await callApi("/peppol/languages", "GET");
			if (!response.ok) {
				throw new Error("Server error");
			}
			const data = await response.json();
			return data;
		} catch (error) {
			return rejectWithValue(error.message);
		}
	}
);

export const getCountriesAllList = createAsyncThunk(
	"customers/getCountriesAllList",
	async (params,
		{rejectWithValue}) => {
		try {
			const response = await callApi("/countries_all", "GET");
			if (!response.ok) {
				throw new Error("Server error");
			}
			const data = await response.json();
			return data;
		} catch (error) {
			return rejectWithValue(error.message);
		}
	}
);

export const addCustomer = createAsyncThunk(
	"customers/addCustomer",
	async (params,
		{rejectWithValue}) => {
		try {
			const response = await callApi("/documents/customer", "POST", params);
			if (!response.ok) {
				throw new Error("Server error");
			}
			const data = await response.json();
			return data;
		} catch (error) {
			return rejectWithValue(error.message);
		}
	}
);

export const editCustomer = createAsyncThunk(
	"customers/editCustomer",
	async (params,
		{rejectWithValue}) => {
		try {
			const response = await callApi("/documents/customer", "PUT", params);
			if (!response.ok) {
				throw new Error("Server error");
			}
			const data = await response.json();
			return data;
		} catch (error) {
			return rejectWithValue(error.message);
		}
	}
);

export const deleteCustomer = createAsyncThunk(
	"manager/deleteCustomer",
	async (id,
		{rejectWithValue}) => {
		try {
			const response = await callApi(`/documents/customer/${id}`, "DELETE");
			if (!response.ok) {
				throw new Error("Server error");
			}
			return await response.json();
		} catch (err) {
			return rejectWithValue(err.message);
		}
	}
);

const initialState = {
	customers: [],
	languages_list: [],
	countries_list: [],
};

const customersSlice = createSlice({
	name: "customers",
	initialState,
	reducers: {},
	extraReducers: (builder) =>
		builder
			.addCase(fetchCustomers.pending, (state) => {
				state.fetchingCustomers = true;
			})
			.addCase(fetchCustomers.fulfilled, (state,
				action) => {
				state.fetchingCustomers = false;
				state.fetchingCustomersError = null;
				state.customers = action.payload;
			})
			.addCase(fetchCustomers.rejected, (state,
				action) => {
				state.fetchingCustomers = false;
				state.fetchingCustomersError = parseServerError(action.error);
			})
			
			.addCase(getLanguagesList.pending, (state) => {
				state.getLanguagesList = true;
			})
			.addCase(getLanguagesList.fulfilled, (state,
				action) => {
				state.getLanguagesList = false;
				state.getLanguagesListError = null;
				state.languages_list = action.payload;
			})
			.addCase(getLanguagesList.rejected, (state,
				action) => {
				state.getLanguagesList = false;
				state.getLanguagesListError = parseServerError(action.error);
			})
			
			.addCase(getCountriesAllList.pending, (state) => {
				state.getCountriesAllList = true;
			})
			.addCase(getCountriesAllList.fulfilled, (state,
				action) => {
				state.getCountriesAllList = false;
				state.getCountriesAllListError = null;
				state.countries_list = action.payload;
			})
			.addCase(getCountriesAllList.rejected, (state,
				action) => {
				state.getCountriesAllList = false;
				state.getCountriesAllListError = parseServerError(action.error);
			})
			
			.addCase(addCustomer.pending, (state) => {
				state.addCustomerLoader = true;
			})
			.addCase(addCustomer.fulfilled, (state,
				action) => {
				state.addCustomerLoader = false;
				state.addCustomerError = null;
			})
			.addCase(addCustomer.rejected, (state,
				action) => {
				state.addCustomerLoader = false;
				state.addCustomerError = parseServerError(action.error);
			})
			
			.addCase(editCustomer.pending, (state) => {
				state.editCustomerLoader = true;
			})
			.addCase(editCustomer.fulfilled, (state,
				action) => {
				state.editCustomerLoader = false;
				state.editCustomerError = null;
			})
			.addCase(editCustomer.rejected, (state,
				action) => {
				state.editCustomerLoader = false;
				state.editCustomerError = parseServerError(action.error);
			})
			
			.addCase(deleteCustomer.pending, (state) => {
				state.deleteCustomerLoader = true;
			})
			.addCase(deleteCustomer.fulfilled, (state,
				action) => {
				state.deleteCustomerLoader = false;
				state.deleteCustomerError = null;
			})
			.addCase(deleteCustomer.rejected, (state,
				action) => {
				state.deleteCustomerLoader = false;
				state.deleteCustomerError = parseServerError(action.error);
			})
});

export default customersSlice.reducer;
