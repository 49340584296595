import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import { deleteCookie, setCookie } from "utils/cookie";
import callApi from "helpers/callApi";
import { fetchUser } from "../user/userSlice";
import handleErrorForThunk from "helpers/handleErrorForThunk";

export const login = createAsyncThunk(
  "auth/login",
  async ({ email, password }, { rejectWithValue }) => {
    try {
      const response = await callApi("/user/login", "POST", {
        email,
        password,
      });
      if (!response.ok) {
        const errorText = await response.text();
        throw new Error(errorText ?? "Server error!");
      }
      const json = await response.json();

      if (!json.two_factor_auth || json.is_manager) {
        storeUserData(json);
        return json;
      }
    } catch (err) {
      return rejectWithValue(handleErrorForThunk(err));
    }
  }
);

export const logout = createAsyncThunk(
  "auth/logout",
  async (_, { rejectWithValue }) => {
    try {
      const response = await callApi("/user/logout", "POST");
      if (!response.ok) {
        throw new Error("Server error");
      }
      localStorage.removeItem("api_key");
      localStorage.removeItem("is_manager");
      deleteCookie("api_key");
      deleteCookie("is_manager");
      return true;
    } catch (err) {
      return rejectWithValue(err.message);
    }
  }
);

export const activateUser = createAsyncThunk(
  "auth/activateUser",
  async (data, { rejectWithValue }) => {
    try {
      const response = await callApi("/user/activate", "POST", data);
      if (!response.ok) {
        throw new Error("Server error");
      }
      const json = await response.json();
      storeUserData(json);
      return true;
    } catch (err) {
      return rejectWithValue(err.message);
    }
  }
);

export const checkAuthenticationCode = createAsyncThunk(
  "auth/checkAuthenticationCode",
  async ({ code, api_key }, { rejectWithValue }) => {
    try {
      const response = await callApi(
        `/mobile/check_authentication_code?authentication_code=${code}`,
        "GET",
        { "api-key": api_key }
      );
      if (!response.ok) {
        throw new Error("Server error");
      }
      const json = await response.json();
      storeUserData(json);
      return json;
    } catch (err) {
      return rejectWithValue(err.message);
    }
  }
);

function storeUserData(json) {
  localStorage.setItem("api_key", json.api_key);
  localStorage.setItem("is_manager", json.is_manager || false);

  const now = new Date();
  const nextWeek = new Date(now.getTime() + 7 * 24 * 60 * 60 * 1000);

  setCookie("api_key", json.api_key, { expires: nextWeek, path: "/" });
  setCookie("is_manager", json.is_manager || false, {
    expires: nextWeek,
    path: "/",
  });
}

const authSlice = createSlice({
  name: "auth",
  initialState: {
    isLogged: false,
    loggingIn: false,
    loggingInLoading: false,
    loggingOut: false,
    activating: false,
    checkAuthenticationCodeLoading: false,
    activationSuccessful: false,
    loginError: null,
    logoutError: null,
    activationError: null,
    checkAuthenticationCodeError: null,
  },
  reducers: {
    startLogin(state) {
      state.isLogged = true;
      state.loggingIn = true;
    },
  },
  
  extraReducers: (builder) => {
    builder
      .addCase(fetchUser.fulfilled, (state, action) => {
        state.isLogged = true;
        state.loggingIn = false;
      })
      .addCase(fetchUser.rejected, (state, action) => {
        state.isLogged = false;
        state.loggingIn = true;
      })
      .addCase(login.pending, (state) => {
        state.loggingIn = true;
        state.loggingInLoading = true;
      })
      .addCase(login.fulfilled, (state) => {
        state.isLogged = true;
        state.loggingIn = false;
        state.loggingInLoading = false;
        state.loginError = null;
      })
      .addCase(login.rejected, (state, action) => {
        state.loggingIn = false;
        state.loggingInLoading = false;
        state.loginError = action.payload;
      })
      .addCase(logout.pending, (state) => {
        state.loggingOut = true;
      })
      .addCase(logout.fulfilled, (state) => {
        state.isLogged = false;
        state.loggingOut = false;
        state.logoutError = null;
      })
      .addCase(logout.rejected, (state, action) => {
        state.loggingOut = false;
        state.logoutError = action.payload;
      })
      .addCase(activateUser.pending, (state) => {
        state.activating = true;
        state.activationError = null;
        state.activationSuccessful = false;
      })
      .addCase(activateUser.fulfilled, (state) => {
        state.activating = false;
        state.activationError = null;
        state.activationSuccessful = true;
        state.isLogged = true;
        state.loggingIn = false;
        state.loginError = null;
      })
      .addCase(activateUser.rejected, (state, action) => {
        state.activating = false;
        state.activationError = action.payload;
        state.activationSuccessful = false;
      })
      .addCase(checkAuthenticationCode.pending, (state) => {
        state.checkAuthenticationCodeLoading = true;
      })
      .addCase(checkAuthenticationCode.fulfilled, (state) => {
        state.checkAuthenticationCodeError = null;
        state.checkAuthenticationCodeLoading = false;
      })
      .addCase(checkAuthenticationCode.rejected, (state, action) => {
        state.checkAuthenticationCodeLoading = false;
        state.checkAuthenticationCodeError = action.payload;
      });
  },
});
export const { startLogin } = authSlice.actions;
export default authSlice.reducer;
