import { Button, TextField } from '@shopify/polaris'
import React, { useEffect, useMemo, useState } from 'react'
import { useSelector } from 'react-redux'
import BannerCustom from '../../../components/Banners/BannerCustom'
import SearchingCountry from '../../../components/SearchingCountry/SearchingCountry'
import useAppDispatch from '../../../hooks/useAppDispatch'
import { fetchAddPeppolRegistration } from '../../../redux/features/look-up/lookUpSlice'

const ApplyPeppolBlock = ({countries, onHide, dataInfo}) => {
	const {registrationMessage, fetchingAddPeppolRegistration} = useSelector(state => state.lookUp)
	const countriesOptions = useMemo(() =>
			countries?.map((country) => ({
				label: country.name,
				value: country.code,
				value2: country.iso_code_2,
			})) || [],
		[countries]
	);

	const [selectedCountry, setSelectedCountry] = useState(null)
	const [companyNameValue, setCompanyNameValue] = useState(dataInfo?.company_name || '')
	const [identifierValue, setIdentifierValue] = useState(dataInfo?.identifier || '')
	const [isDisabled, setIsDisabled] = useState(true)
	const [countdown, setCountdown] = useState(5)
	const [step, setStep] = useState(2)
	const dispatch = useAppDispatch()
	
	useEffect(() => {
		if (dataInfo?.country_code) {
			const foundCountry = countriesOptions.find((item) => item.value2 === dataInfo.country_code)
			setSelectedCountry(foundCountry || null)
		}
	}, [dataInfo])
	
	useEffect(() => {
		if (step === 2) {
			const timer = setInterval(() => {
				setCountdown((prev) => {
					if (prev === 1) {
						clearInterval(timer)
						setIsDisabled(false)
						return 0
					}
					return prev - 1
				})
			}, 1000)
			
			return () => clearInterval(timer)
		} else {
			setCountdown(5)
			setIsDisabled(true)
		}
	}, [step])
	
	const clearData = () => {
		setSelectedCountry(null)
		setCompanyNameValue('')
		setIdentifierValue('')
		setCountdown(5)
		setIsDisabled(true)
		setStep(1)
		dispatch({ type: 'CLEAR_REGISTRATION_MESSAGE' });
	}
	
	const registrationPeppolHandler = async () => {
		const isoCode = countries?.find((item) => {
			return item?.code === (typeof selectedCountry === 'object' ? selectedCountry?.value : selectedCountry);
		});
		const tehData = {
			company_name: companyNameValue,
			country_code: isoCode?.iso_code_2,
			identifier: identifierValue,
			identifier_code: dataInfo?.identifier_code,
			identifier_scheme: dataInfo?.identifier_scheme,
		}
		console.log('tehData', tehData)
		console.log('dataInfo', dataInfo)
		if (tehData) {
			try {
				dispatch(fetchAddPeppolRegistration(tehData))
				if (registrationMessage?.error && !fetchingAddPeppolRegistration) {
					onHide()
					clearData()
				}
				
			} catch (e) {
				console.error(e.message || 'Unexpected error')
			}
		}
	}
	
	return (
		<div style={{marginTop: 10, padding: '10px 20px'}}>
			{
				registrationMessage?.error
				&&
				<BannerCustom
					status={'critical'}
					message={registrationMessage?.error}
				/>
			}
			{step === 1 ? (
				<>
					<div style={{marginBottom: 10}}>
						<TextField
							label='Company Name'
							value={companyNameValue}
							placeholder='Enter Company Name'
							onChange={setCompanyNameValue}
							autoComplete='off'
						/>
					</div >

					<SearchingCountry
						id='country_id'
						title='Country'
						placeholder='Enter your Country'
						onChange={setSelectedCountry}
						changeCountry={selectedCountry?.label || ''}
						dataCountriesList={countriesOptions}
						isOpen={true}
					/>

					<div style={{marginTop: 10}}>
						<TextField
							label='Identifier'
							value={identifierValue}
							placeholder='Enter Identifier'
							onChange={setIdentifierValue}
							autoComplete='off'
						/>
					</div >

					<div style={{marginTop: 20, display: 'flex', alignItems: 'center'}}>
						<Button
							size='micro'
							variant='primary'
							onClick={() => setStep(2)}
						>
							Next
						</Button >

						<div style={{marginLeft: 10}}>
							<Button
								variant='secondary'
								size='micro'
								onClick={onHide}
							>
								Cancel
							</Button >
						</div >
					</div >
				</>
			) : (
				<>
					<div style={{marginBottom: 10}}>
						<TextField
							label='Company Name'
							value={companyNameValue}
							placeholder='Enter Company Name'
							onChange={setCompanyNameValue}
							autoComplete='off'
							disabled
						/>
					</div >

					<SearchingCountry
						id='country_id'
						title='Country'
						placeholder='Enter your Country'
						onChange={setSelectedCountry}
						changeCountry={selectedCountry?.label || ''}
						dataCountriesList={countriesOptions}
						isOpen={true}
						disabled
					/>

					<div style={{marginTop: 10}}>
						<TextField
							label='Identifier'
							value={identifierValue}
							placeholder='Enter Identifier'
							onChange={setIdentifierValue}
							autoComplete='off'
							disabled
						/>
					</div >

					<div style={{marginTop: 20, display: 'flex', alignItems: 'center'}}>
						<Button
							size='micro'
							variant='primary'
							disabled={isDisabled}
							loading={fetchingAddPeppolRegistration}
							onClick={() => registrationPeppolHandler()}
						>
							 {isDisabled ? (
								 `You can press in (${countdown}) sec`
							 ) : (
								 'Apply peppol number'
							 )}
						</Button >

						<div style={{marginLeft: 10}}>
							<Button
								variant='secondary'
								size='micro'
								onClick={() => setStep(1)}
							>
								Edit
							</Button >
						</div >
					</div >
				</>
			)}
		</div >
	)
}

export default ApplyPeppolBlock
