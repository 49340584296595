import { Button, Layout, Page, Spinner, Text, TextField } from '@shopify/polaris'
import React, { useState } from 'react'
import { useSelector } from 'react-redux'
import useAppDispatch from '../../hooks/useAppDispatch'
import { fetchLookUp } from '../../redux/features/look-up/lookUpSlice'
import '../style/style.css'

const LookUp = () => {
	const [lookupValue, setLookupValue] = useState('')
	const {look_up, fetchingLookUp} = useSelector(state => state.lookUp)
	const dispatch = useAppDispatch()
	
	const [error, setError] = useState('')
	
	// console.log('lookup_list', look_up)
	// console.log('fetchingLookUp', fetchingLookUp)
	
	
	const submitHandler = async () => {
		setError('')
		try {
			// const { data, error } = await lookup({ company_name: lookupValue })
			dispatch(fetchLookUp({company_name: lookupValue}))
			// if (error || data?.error) setError(error || data?.error)
		} catch (e) {
			console.error(e)
			setError(e)
		}
	}

	return (
		<Page
			separator
			fullWidth
			title={
				<Text
					variant='heading3xl'
					as='span'
				>
          Look Up
        </Text >
			}
			subtitle={
				<Text
					variant='bodyLg'
					as='span'
					color='subdued'
				>
        
        </Text >
			}
		>
      {/*<PageHelmet title={"Look Up"} />*/}

      <Layout >
	      <Layout.Section >
					<div className='lookup-wrapper'>
						<div className='lookup-wrapper_search'>
							<div className='lookup-wrapper_search-top'>
								<div className='lookup-wrapper_search-label'>
									<TextField
										type='search'
										value={lookupValue}
										onChange={(value) => setLookupValue(value)}
										autoComplete='off'
									/>
								</div >
								<p className='lookup-wrapper_search-text'>
									Enter the name, address, ID or any other keyword of the entity you are looking for.
								</p >
								<Button
									onClick={submitHandler}
									variant='primary'
									tone={'success'}
									disabled={!lookupValue}
									fullWidth
								>
									<svg
										xmlns='http://www.w3.org/2000/svg'
										width='16'
										height='16'
										viewBox='0 0 16 16'
										fill='none'
										style={{marginRight: 10}}
									>
		              <circle
			              cx='7'
			              cy='7'
			              r='5'
			              stroke='#fff'
			              strokeWidth='2'
			              fill='none'
		              />
		              <line
			              x1='10'
			              y1='10'
			              x2='14'
			              y2='14'
			              stroke='#fff'
			              strokeWidth='2'
		              />
		            </svg >
									Search Peppol Directory
								</Button >
							</div >
						</div >
						{
							error
							&&
							<div className='lookup-wrapper_error'>
								{error}
							</div >
						}
						<div className='lookup-wrapper_container'>
							{
								fetchingLookUp
									?
									<Spinner />
									:
									look_up?.length > 0
									&&
									look_up?.map((item) => (
										<div
											className='lookup-wrapper_container-block'
											key={item?.peppol_id}
										>
											<div className='lookup-wrapper_container-block_id'>
												<p className='lookup-wrapper_container-block_id-title'>
													Peppol ID:
												</p >
												<p className='lookup-wrapper_container-block_id-value'>
													{item?.peppol_id}
												</p >
											</div >
											<div className='lookup-wrapper_container-block_country'>
												<p className='lookup-wrapper_container-block_country-title'>
													Country:
												</p >
												<p className='lookup-wrapper_container-block_country-value'>
													{/*<CountryFlag countryCode={item?.country?.code} />*/}
												</p >
												<p className='lookup-wrapper_container-block_country-value'>
													{item?.country?.name}
												</p >
											</div >
											<div className='lookup-wrapper_container-block_name'>
												<p className='lookup-wrapper_container-block_name-title'>
													Company name:
												</p >
												<p className='lookup-wrapper_container-block_name-value'>
													{item?.company_name}
												</p >
											</div >
											<div className='lookup-wrapper_container-block_date'>
												<p className='lookup-wrapper_container-block_date-title'>
													Register date:
												</p >
												<p className='lookup-wrapper_container-block_date-value'>
													{item?.reg_date}
												</p >
											</div >
											{/*<Button*/}
											{/*	// onClick={submitHandler}*/}
											{/*	variant='success'*/}
											{/*	className='lookup-wrapper_container-block_button'*/}
											{/*	disabled*/}
											{/*>*/}
											{/*	<SearchIcon className='lookup-wrapper_container-block_button-icon' />*/}
											{/*	Show details*/}
											{/*</Button >*/}
									</div >
									))
							}
						</div >
					</div >
	      </Layout.Section >
      </Layout >
		</Page >
	)
}

export default LookUp