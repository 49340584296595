import { useState } from "react";

const useTabsForEDocuments = () => {
  const [selectedTab, setSelectedTab] = useState(0);

  const tabs = [
    {
      id: "vat_invoice",
      content: "VAT Invoices",
      accessibilityLabel: "VAT Invoices",
      panelID: "vat",
    },
    {
      id: "sales_tax_invoice",
      content: "Sales Tax Invoices",
      accessibilityLabel: "Sales Tax Invoices",
      panelID: "sales",
    },
  ];

  const handleTabSelect = (tabIndex) => setSelectedTab(tabIndex);

  return { tabs, selectedTab, handleTabSelect };
};

export default useTabsForEDocuments;
