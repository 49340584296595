import { Box, Button, ButtonGroup, Card } from '@shopify/polaris'
import React, { useEffect, useState } from 'react'
import { useSelector } from 'react-redux'
import useAppDispatch from '../../../hooks/useAppDispatch'
import {
	fetchCountriesLanguage,
	fetchLanguages,
	fetchPeppolCountriesList
} from '../../../redux/features/look-up/lookUpSlice'
import DeleteModalForSettings from '../DeleteModalForSettings/DeleteModalForSettings'
import AddLanguageForPDFBlock from './AddLanguageForPDFBlock'
import TableLanguageForPDF from './TableLanguageForPDF'

const LanguageForPdFs = () => {
	const {countries_language, fetchingCountriesLanguage, countries_list, languages} = useSelector(state => state.lookUp)
	
	const [showAddLanguageForPDFBlock, setShowAddLanguageForPDFBlock] = useState(false)
	const [editLanguageForPDFBlockValue, setEditLanguageForPDFBlockValue] = useState({})
	const [showDeleteModal, setShowDeleteModal] = useState(false)
	
	const dispatch = useAppDispatch()
	
	const toggleAddLanguageForPDFBlockShowBlock = () => {
		setShowAddLanguageForPDFBlock(!showAddLanguageForPDFBlock)
		showAddLanguageForPDFBlock === false && setEditLanguageForPDFBlockValue({})
	}
	
	const reUpdateData = async () => {
		await dispatch(fetchCountriesLanguage())
	}
	
	const onEditHandler = (item) => {
		setShowAddLanguageForPDFBlock(true)
		setEditLanguageForPDFBlockValue(item)
	}
	
	const onDeleteHandler = (item) => {
		setShowDeleteModal(true)
		setEditLanguageForPDFBlockValue(item)
	}
	
	const onCloseDeleteModalHandler = () => {
		setShowDeleteModal(false)
	}
	
	useEffect(() => {
		const req = async () => {
			try {
				await dispatch(fetchCountriesLanguage())
				await dispatch(fetchPeppolCountriesList())
				await dispatch(fetchLanguages())
			} catch (e) {
			
			}
		}
		req()
	}, [])
	
	return (
		<Card >
			{
				showDeleteModal
				&&
				<DeleteModalForSettings
					show={showDeleteModal}
					onClose={onCloseDeleteModalHandler}
					data={editLanguageForPDFBlockValue}
					block={'languageBlock'}
					reUpdateData={reUpdateData}
				/>
			}
      <div >
	      <Box
		      display='flex'
		      flexDirection='column'
		      gap='5'
		      width='100%'
	      >
		      <ButtonGroup >
	          <Button
		          variant='primary'
		          size={'micro'}
		          onClick={toggleAddLanguageForPDFBlockShowBlock}
	          >
	            Add Country for e-docs
	          </Button >
	        </ButtonGroup >
		      
		      {
						showAddLanguageForPDFBlock
			      &&
			      <AddLanguageForPDFBlock
				      countries={countries_list}
				      languages={languages}
				      editData={editLanguageForPDFBlockValue}
				      onHide={toggleAddLanguageForPDFBlockShowBlock}
				      reUpdateData={reUpdateData}
			      />
		      }
		      
	        <div style={{display: 'flex', marginTop: 30}}>
		        <TableLanguageForPDF
			        dataTable={countries_language || []}
			        loadingTable={fetchingCountriesLanguage}
			        onEdit={onEditHandler}
			        onDelete={onDeleteHandler}
		        />
	        </div >
	      </Box >
	    </div >
    </Card >
	)
}

export default LanguageForPdFs