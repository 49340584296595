import { Button, ButtonGroup } from '@shopify/polaris'
import React from 'react'
import { useTranslation } from 'react-i18next'
import { useSelector } from 'react-redux'
import Modal from '../../components/Modal'
import useAppDispatch from '../../hooks/useAppDispatch'
import { deleteCustomer } from '../../redux/features/customers/customersSlice'

const DeleteCustomerModal = ({show, onHide, data}) => {
	const {deleteCustomerLoader} = useSelector(state => state.customers)
	const {t} = useTranslation()
	const dispatch = useAppDispatch()
	
	const deleteCustomerHandler = async () => {
		try {
			await dispatch(deleteCustomer(data?.id))
			onHide()
		}catch (e) {
			console.log(e)
		}
	}
	
	return (
		<Modal
			title={'Delete'}
			iconType={"danger"}
			description={`Are you sure you want to delete the customer "${data?.name}"? This action cannot be undone.`}
			visible={show}
			onClose={onHide}
			footer={
				<ButtonGroup fullWidth>
          <Button
            onClick={onHide}
            size='large'
          >
            {t("billing.cancel")}
          </Button >
          <Button
            onClick={deleteCustomerHandler}
            tone={'critical'}
            variant={'plain'}
            size='large'
            loading={deleteCustomerLoader}
          >
            {t("billing.delete")}
          </Button >
        </ButtonGroup >
			}
		/>
	)
}

export default DeleteCustomerModal